import React from 'react';
import { render } from 'react-dom';

import App from './App';
import { StateProvider } from './ContextApi/StateProvider';
import reducer, { initialState } from './ContextApi/Reducer';

const mountApp = document.getElementById('root');
render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <App />
  </StateProvider>,
  mountApp
);

if (process.env.NODE_ENV === 'development') {
  module.hot.accept();
}
