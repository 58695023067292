import React, { useState, useEffect } from 'react';
import useApi from '../../api/useApi';
import { UPDATE_INCIDENT } from '../../api/urls';
import moment from 'moment';

function ViewIncident({ handleClose, selectedIncident, refreshList }) {
  const formDataModel = {
    action: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, updateIncident] = useApi();

  useEffect(() => {
    if (selectedIncident) {
      setFormData(selectedIncident);
    }
  }, [selectedIncident]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateIncident(UPDATE_INCIDENT(selectedIncident.postId), 'PUT', formData);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 201) {
      handleClose();
      refreshList();
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-auto min-h-full w-full absolute left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-2/3 text-sm block m-auto mt-10 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold text-textBlue1 ">Ankit Awasthi - 2021DLAS01001 </span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
        </div>
        <form className="mb-6 mt-4 px-6 " onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-x-4">
            <div className="my-2.5 w-full relative font-medium opacity-60">
              <label className="block text-textDark text-sm">Date</label>
              <p className="w-full  rounded-3xl text-textDark border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none">
                {selectedIncident?.date ? moment(selectedIncident.date).format('ll') : ''}
              </p>
            </div>

            <div className="my-2.5 w-full relative font-medium col-span-2 opacity-60">
              <label className="block text-textDark text-sm">Incident Detail</label>
              <p className="w-full rounded-xl text-textDark border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none h-20">
                {selectedIncident?.incident}
              </p>
            </div>

            {selectedIncident.is_active ? (
              <div className="my-2.5 w-full relative font-medium col-span-2">
                <label className="block text-textDark text-sm">Action Taken</label>
                <input
                  className="w-full rounded-xl text-textDark border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none h-20"
                  value={formData?.action}
                  name="action"
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              <div className="my-2.5 w-full relative font-medium col-span-2 opacity-60">
                <label className="block text-textDark text-sm">Action Taken</label>
                <p className="w-full rounded-xl text-textDark border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none h-20">
                  {selectedIncident?.action}
                </p>
              </div>
            )}
          </div>

          {selectedIncident.is_active && (
            <button
              type="submit"
              className="border border-tabtext-tableRowText ml-auto mt-4 px-8 py-2 rounded-3xl bg-primary text-white text-xs mx-1 focus:outline-none flex justify-center text-center items-center w-36"
            >
              Save
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default ViewIncident;
