import React, { useEffect, useState } from 'react';
import useApi from '../api/useApi';
import { ROLEDIN_FEEDBACKS } from '../api/urls';

function Feedbacks() {
  const [{ isLoading, isError, data }, getFeedbacks] = useApi();

  useEffect(() => {
    getFeedbacks(ROLEDIN_FEEDBACKS, 'GET');
  }, []);

  return (
    <div className="w-4/5 pb-10">
      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/3">
              <p>RoledIn Feedbacks</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/12 border-r-2 py-2 px-2">Screen</div>
                  <div className="w-2/12 border-r-2 py-2 px-2">Feature</div>
                  <div className="w-6/12 border-r-2 py-2 px-2">Feedback</div>
                  <div className="w-2/12 py-2 px-2">Feedback By</div>
                </div>
              </div>
              <div className="customScrshowScrollollBar" style={{ maxHeight: '55vh' }}>
                {data &&
                  Array.isArray(data) &&
                  data.map((x, idx) => {
                    return (
                      <div className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                            data.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-2/12 px-2 text-tableRowText ">{x?.category}</div>
                          <div className="w-2/12 px-2 text-tableRowText ">{x?.feature}</div>
                          <div className="w-6/12 px-2 text-tableRowText ">{x?.remarks}</div>
                          <div className="w-2/12 px-2 text-tableRowText ">
                            {x?.userId?.full_name + ' (' + x?.userId?.username + ')'}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feedbacks;
