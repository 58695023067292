import styled from 'styled-components';

export const CreatePostWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #e5e5e5ab;
  z-index: 6;
`;

export const CreatePOstWithPicture = styled.form`
  position: absolute;
  top: 10%;
  width: 100%;
`;
export const Divider = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 209.17px;
`;
export const FlexWrapperOne = styled.div`
  background-color: white;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 3%;
  top: 7%;
  width: 94%;

  @media screen and (min-width: 768px) {
    left: 50%;
    top: 10%;
    width: 40%;
    transform: translate(-50%, 0%);
  }
`;
export const FlexWrapperEight = styled.div`
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  postition: relative;
  width: 100%;

  margin-bottom: 1em;
`;
export const CreatePostLabel = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`;
export const CloseWindowIcon = styled.div`
  width: 1.5em;
  padding: 3.56px;
  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
`;
export const Vector = styled.img`
  width: 100%;
  height: 100%;
`;
export const DividerTwo = styled.div`
  height: 0.12%;

  margin-bottom: 17.79px;
  align-self: stretch;
`;
export const PortText = styled.textarea`
  width: 100%;
  // height: 10.98%;
  color: black;
  // margin-bottom: 33.53px;
  padding: 0 26.33px;
  // font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
`;

export const UploadImg = styled.input`
  margin: 1.5em;
  margin-bottom: 4em;
`;

export const FlexWrapperSix = styled.div`
  background-size: contain;
  border-radius: 14px;
  background-image: url(${(props) => (props.url ? props.url : ``)});
  width: auto;
  background-repeat: no-repeat;
  margin: auto;
  background-position: center;
  height: 220px;
  margin-bottom: 39.28px;
  // margin-left: 29.17px;
  // padding: 12.1px 0 0.2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;
export const CancelPicture = styled.div`
  border-radius: 50%;
  position: absolute;
  right: 2%;
  top: 6%;
`;
export const CloseIcon = styled.div`
  width: 20px;
  height: 20px;
  padding: 4px;
`;
export const FlexWrapperSeven = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0px 0px 14px 14px;
  padding: 7.11px 456.17px 6.37px 14.95px;
  display: flex;
  align-items: center;
  margin-top: auto;
`;
export const NoOfPictures = styled.p`
  color: #00000080;
  font-size: 10px;
`;

export const FlexWrapperNine = styled.div`
  margin-bottom: 13.33px;
  padding: 0 0 0 25.97px;
  display: flex;
  align-items: center;
`;
export const SelectSchoolLabel = styled.p`
  width: 100%;
  font-weight: 600;

  align-self: stretch;
  margin-right: 3.99px;
  font-size: 1rem;
`;
export const SelectMultipleAffordance = styled.p`
  width: 51.99%;
  height: 61.54%;
`;
export const FlexWrapperTen = styled.div`
  margin-bottom: 20.62px;
  padding: 0 0 0 35.22px;
  display: flex;
  align-items: center;
`;
export const FlexWrapperThree = styled.input`
  margin-right: 16.36px;
  padding: 2.13px 2.14px 2.14px 2.13px;
  display: flex;
  align-items: center;
  width: 20px;
  border: 1px solid grey;
  height: 20px;
  border-radius: 5px;
`;
export const SchoolName1 = styled.p`
  width: 104.58%;
  height: 79.17%;
  color: rgba(33, 33, 33, 0.72);
  /* margin-top: 4px; */
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;
export const FlexWrapperEleven = styled.div`
  margin-bottom: 19.21px;
  padding: 0 0 0 35.22px;
  display: flex;
  align-items: center;
`;
export const FlexWrapperFour = styled.div`
  margin-right: 16.36px;
  padding: 2.14px 2.14px 2.13px 2.13px;
  display: flex;
  align-items: center;
`;
export const SchoolName2 = styled.p`
  width: 104.58%;
  height: 79.17%;
  color: rgba(33, 33, 33, 0.72);
  /* margin-top: 4px; */
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;
export const FlexWrapperTwelve = styled.div`
  margin-bottom: 17.07px;
  padding: 0 0 0 35.22px;
  display: flex;
  align-items: center;
`;
export const SchoolName3 = styled.p`
  width: 104.58%;
  height: 79.17%;
  color: rgba(33, 33, 33, 0.72);
  /* margin-top: 4px; */
  // font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`;
FlexWrapperTwoDiv;
export const FlexWrapperTwo = styled.button`
  border-radius: 26px;
  padding: 0.7rem 3rem;
  display: flex;
  align-items: center;
  margin: 1rem;
  background: #f27900;
  margin-left: auto;
`;

export const FlexWrapperTwoDiv = styled.div`
  float: right;
  width: 100%;
`;
export const PostLabel = styled.p`
  color: rgba(255, 255, 255, 1);
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  // font-family: Roboto;
  font-size: 14px;
  background-color: #f27900;
  font-weight: 400;
  line-height: normal;
`;
