import React, { useState, useEffect } from 'react';

import useApi from '../../api/useApi';
import 'react-datepicker/dist/react-datepicker.css';
import { UPDATE_FEE_STRUCTURE } from '../../api/urls';

function EditFeeStructure({ handleClose, refreshList, feeTypes, selectedInstitution }) {
  const [selectedFeeStructure, selectFeeStructure] = useState(selectedInstitution?.feeStructure);

  const [{ isLoading, isError, data, status }, editFeeStructure] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedFeeStructure) {
      alert(`Please select Fee Submission type`);
      return;
    }

    const body = {
      feeStructure: selectedFeeStructure,
    };

    editFeeStructure(UPDATE_FEE_STRUCTURE(selectedInstitution?.instituteId?._id), 'PUT', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 201) {
      handleClose();
      refreshList();
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert(data || 'Failed');
    }
  }, [isLoading, isError, data, status]);

  const handleSelect = (x) => {
    const m = selectedFeeStructure;
    const index = m.findIndex((c) => c === x);
    if (index > -1) {
      m.splice(index, 1);
      console.log(m);
      selectFeeStructure([...m]);
    } else selectFeeStructure([...m, x]);
  };

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">{selectedInstitution?.instituteId?.name}</span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col w-full px-8 mt-4">
            <p className=" font-medium ">Fee Submission Information</p>

            <div className="my-2 w-full relative font-semibold ">
              {/* <label htmlFor="category" className="text-textDark font-medium text-sm">
                Fee Submission Type<span className="text-red-500 ml-1">*</span>
              </label> */}

              <div className="mt-2 gap-x-10 flex">
                {feeTypes.map((x) => (
                  <label className="gap-x-2 flex">
                    <input
                      key={x}
                      type="checkbox"
                      checked={selectedFeeStructure.includes(x)}
                      onChange={() => {
                        handleSelect(x);
                      }}
                    />
                    {x}
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className="flex gap-x-4 justify-end px-8 mb-4">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto mt-2" alt="" />
            ) : (
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                type="submit"
              >
                Edit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditFeeStructure;
