import React, { useState, useEffect } from 'react';

import useApi from '../../api/useApi';
import { INSTITUTE_TIMIMG_LOCATION } from '../../api/urls';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function ShiftGeoLocationDetails({ selectedInstitution, handleClose, refreshList }) {
  const formDataModel = {
    lat: '',
    lng: '',
    from: '',
    to: '',
    radius: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, addData] = useApi();

  useEffect(() => {
    if (selectedInstitution?.locationTiming) {
      setFormData({
        lat: selectedInstitution?.locationTiming?.instituteCord?.latitude,
        lng: selectedInstitution?.locationTiming?.instituteCord?.longitude,
        from: new Date(selectedInstitution?.locationTiming?.shiftInTime),
        to: new Date(selectedInstitution?.locationTiming?.shiftOutTime),
        radius: selectedInstitution?.locationTiming?.radius,
      });
    }
  }, [selectedInstitution]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      instituteId: selectedInstitution?._id,
      instituteName: selectedInstitution.name,
      shiftInTime: formData.from,
      shiftOutTime: formData.to,
      shiftTotalHour: +moment
        .duration(moment(formData.to).diff(moment(formData.from)))
        .asHours()
        .toFixed(2),
      radius: +formData.radius,
      instituteCord: {
        latitude: formData.lat,
        longitude: formData.lng,
      },
    };
    console.log(body);
    addData(INSTITUTE_TIMIMG_LOCATION, 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && (status == 200 || status == 201)) {
      setFormData(formDataModel);
      handleClose();
      refreshList();
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert('FAILED');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">
            {/* {selectedInstitution ? formData.name : 'Create Institution'} */}
            Institute Location / Timimg
          </span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className=" w-full h-1/4 px-4 pb-6">
          <form className="grid grid-cols-2 gap-x-6 px-4 pb-2" onSubmit={handleSubmit}>
            <div className="my-2 relative font-semibold w-full col-span-2">
              <label className="text-sm text-textDark w-1/2">Institution</label>
              <input
                type="text"
                disabled
                name="name"
                // placeholder="All"
                value={selectedInstitution?.name}
                // onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <p className="font-semibold col-span-2 mt-3">Coordinates</p>
            <div className="my-2 w-full relative font-semibold ">
              <label className="text-sm text-textDark">Latitude</label>
              <input
                name="lat"
                required
                value={formData.lat}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              />
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label className="text-sm text-textDark">Longitude</label>
              <input
                name="lng"
                required
                value={formData.lng}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2 relative font-semibold w-full">
              <label className="text-sm text-textDark">Radius</label>
              <input
                type="number"
                name="radius"
                required
                // placeholder="All"
                value={formData.radius}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <p className="font-semibold col-span-2 mt-3">Timing</p>

            <div className="my-2 w-full relative font-semibold flex flex-col">
              <label className="text-sm text-textDark">From</label>
              <DatePicker
                selected={formData.from}
                onChange={(time) => setFormData({ ...formData, from: time })}
                showTimeSelect
                required
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2 w-full relative font-semibold flex flex-col">
              <label className="text-sm text-textDark">To</label>
              <DatePicker
                selected={formData.to}
                onChange={(time) => setFormData({ ...formData, to: time })}
                showTimeSelect
                required
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 mx-auto" alt="" />
              ) : (
                <>
                  <button
                    className="border border-tableRowText text-textDark px-12 py-2 text-xs rounded-3xl font-medium mx-1 focus:outline-none flex justify-center text-center items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                  >
                    {!selectedInstitution?.locationTiming ? 'Save' : 'Edit'}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ShiftGeoLocationDetails;
