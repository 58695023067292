import React, { useState, useEffect } from 'react';

import useApi from '../../api/useApi';
import { UPDATE_INSTITUTE_SESSION } from '../../api/urls';
import moment from 'moment';

function AddSession({ handleClose, selectedInstitution }) {
  const formDataModel = {
    startDate: '',
    endDate: '',
  };

  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createSession] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      startDate: new Date(formData.startDate),
      endDate: new Date(formData.endDate),
    };

    createSession(UPDATE_INSTITUTE_SESSION(selectedInstitution?._id), 'PUT', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && (status == 200 || status == 201)) {
      setFormData(formDataModel);
      handleClose(true);
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert(data || 'FAILED');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">
            {selectedInstitution?.currentSession ? 'Update Session' : 'Create New Session'}
          </span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className=" w-full h-1/4 px-4 pb-6">
          {selectedInstitution?.currentSession && (
            <div className="px-4 my-4">
              <p className="mb-1 font-medium">Current Session:</p>
              <p>{`${moment(selectedInstitution?.currentSession?.startDate).format('ll')} - ${moment(
                selectedInstitution?.currentSession?.endDate
              ).format('ll')}`}</p>
            </div>
          )}
          <form className="grid grid-cols-2 gap-x-6 px-4 pb-2" onSubmit={handleSubmit}>
            {!selectedInstitution?.isEdit && (
              <div className="my-2 relative font-semibold w-full">
                <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                  Start Date
                </label>
                <input
                  type="date"
                  name="startDate"
                  // placeholder="All"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                />
              </div>
            )}

            <div className="my-2 w-full relative font-semibold ">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                End Date
              </label>
              <input
                type="date"
                name="endDate"
                // placeholder="endDate"
                value={formData.endDate}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                onChange={handleInputChange}
              />
            </div>

            <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <>
                  <button
                    type="submit"
                    className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                  >
                    {'Save'}
                  </button>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddSession;
