import React, { useEffect, useState } from 'react';
import useApi from '../api/useApi';
import { GET_OWNERS_PRINCIPALS, DELETE_USER } from '../api/urls';
import CreateOwner from '../components/Owner/CreateOwner';

function Home() {
  const [owners, setOwners] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedOwner, selectOwner] = useState();

  const [ownerRes, getOwners] = useApi();
  const [deleteRes, deleteOwner] = useApi();

  useEffect(() => {
    getOwners(GET_OWNERS_PRINCIPALS('pr'), 'GET');
  }, []);

  useEffect(() => {
    if (!ownerRes.isLoading && !ownerRes.isError && ownerRes.data) {
      setOwners(ownerRes.data);
    }
  }, [ownerRes]);

  const refreshList = () => {
    getOwners(GET_OWNERS_PRINCIPALS('pr'), 'GET');
  };

  const handleDelete = (x) => {
    const z = confirm('Are you sure you want to delete?');
    if (z) deleteOwner(DELETE_USER(x._id, 'pr'), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status == 200) {
      refreshList();
      alert('SUCCESS');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert('FAILED');
    }
  }, [deleteRes]);

  return (
    <>
      {openAdd && (
        <CreateOwner
          handleClose={() => {
            setOpenAdd(false);
            selectOwner(null);
          }}
          type="Principal"
          refreshList={refreshList}
          selectedOwner={selectedOwner}
        />
      )}
      <div className="w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-4">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="flex justify-between items-center">
              <div className="font-bold w-1/3">
                <p>Principals</p>
              </div>
              <div className="flex gap-x-4 w-2/3 justify-end">
                <button
                  className="w-32 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                  onClick={() => setOpenAdd(true)}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4 px-6">
            <div className="w-full py-4">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Institute</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">User Name</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Name</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Email</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Phone</div>
                    <div className="w-1/6 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="showScroll overflow-auto" style={{ maxHeight: '55vh' }}>
                  {Array.isArray(owners) &&
                    owners.map((x, idx) => {
                      return (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                              owners.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-1/6 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.instituteId?.name}
                            </div>
                            <div className="w-1/6 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.username}
                            </div>
                            <div className="w-1/6 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.full_name}
                            </div>
                            <div className="w-1/6 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.email_id}
                            </div>
                            <div className="w-1/6 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                              {x?.phone}
                            </div>

                            <div className={`w-1/6 px-2 gap-x-2 flex`}>
                              <button
                                className="w-1/2 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                onClick={() => {
                                  setOpenAdd(true);
                                  selectOwner(x);
                                }}
                              >
                                View
                              </button>
                              <button
                                className="w-1/2 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                onClick={() => handleDelete(x)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
