import React, { useState, useEffect, useRef } from 'react';

import useApi from '../../api/useApi';
import DropDown from '../UI/DropDown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GET_INSTITUTES, POST_OWNER, POST_Update_OWNER } from '../../api/urls';
import moment from 'moment';

function CreateOwner({ handleClose, refreshList, selectedOwner, type }) {
  const formDataModel = {
    email_id: '',
    full_name: '',
    gender: '',
    phone: '',
    aadhar: '',
    role: type === 'Owner / Director' ? 'ow' : 'pr',
    dateOfBirth: '',
    // password: 'Qwerty123.',
  };
  const isOwner = type == 'Owner / Director' ? true : false ;
  const addressModel = {
    hno: '',
    society: '',
    district: '',
    city: '',
    state: '',
    pincode: '',
  };
  const roleConvert = (roleStr) => { console.log(" roleConvert : roleStr", roleStr )
    let retStr = roleStr ; 
    if( roleStr == "Owner" ){  retStr = "ow" ; }
    else if( roleStr == "Director" ){ retStr = "dir" ;  }
    else if(selectedOwner){
      if( selectedOwner?.currentlyMapped?.role.findIndex(e => e == 'dir') > -1 ){ retStr = "dir" ;  }
      else if( selectedOwner?.currentlyMapped?.role.findIndex(e => e == 'ow') > -1 ){ retStr = "ow" ;  }
      else if( selectedOwner?.currentlyMapped?.role.findIndex(e => e == 'pr') > -1 ){ retStr = "pr" ;  }
      else {  retStr = "ow" ; } 
    }
    return retStr ;
  }

  const imgRef = useRef();

  const [formData, setFormData] = useState(formDataModel);
  const [addressState, setAddressState] = useState(addressModel);
  const [profileImg, setProfileImg] = useState();
  const [image, setImage] = useState();
  const [institutes, setInstitutes] = useState([]);
  const [selectedInstitution, selectInstitution] = useState(selectedOwner? selectedOwner.instituteId._id : null);

  const [instituteRes, getInstitutes] = useApi();

  useEffect(() => {
    getInstitutes(GET_INSTITUTES, 'GET');
    // console.log(" -- selectedInstitution : ", selectedInstitution )
  }, []);

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
      setInstitutes(instituteRes.data.response);
    }
  }, [instituteRes]);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleAddressInputChange = (e) => setAddressState({ ...addressState, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createOwner] = useApi();

  useEffect(() => {
    if (profileImg) {
      setImage(URL.createObjectURL(profileImg));
    }
  }, [profileImg]);

  useEffect(() => {
    if (selectedOwner) {
      setFormData(selectedOwner);
      setAddressState(selectedOwner.address);

      if (selectedOwner.fileArray.length > 0 && selectedOwner.fileArray[0].profileImg.location)
        setImage(selectedOwner.fileArray[0].profileImg.location);
    }
  }, [selectedOwner]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData2 = new FormData();
    formData2.append('email_id', formData.email_id);
    formData2.append('full_name', formData.full_name);
    formData2.append('gender', formData.gender);
    formData2.append('dateOfBirth', formData.dateOfBirth);
    formData2.append('phone', formData.phone);
    formData2.append('aadhar', formData.aadhar);
    formData2.append('role', roleConvert(formData.role) ); // pr => pr , Owner => ow ,Director => dir
    formData2.append('address', JSON.stringify(addressState));
    formData2.append('password', formData.password);
    formData2.append('profileImg', profileImg);
    //
    // for (const pair of formData2.entries()) {
    //   console.log("formdata2 : ",pair[0], " : ", pair[1]);
    // }  
    // return false ;
    if( selectedOwner ){ 
      if( selectedInstitution )
      { createOwner(POST_Update_OWNER(selectedInstitution,selectedOwner._id), 'POST', formData2); }
      else {  alert("Error"); }
    } else{ createOwner(POST_OWNER(selectedInstitution), 'POST', formData2); }
    
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 200) {
      setFormData(formDataModel);
      setAddressState(addressModel);
      handleClose();
      refreshList();
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert('Failed');
    }
  }, [isLoading, isError, data, status]);
  // 
  // console.log(" -- selectedOwner?.role : ", selectedOwner?.role );

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">
            {selectedOwner ? formData.full_name : 'Create ' + type}
          </span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="px-8 border-b border-borderYellow">
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full ">
            {!selectedOwner ? ( 
              <div className="my-4 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Select Institution<span className="text-red-500">*</span>
                </label>
                <DropDown
                  width={'w-full'}
                  title={'Select'}
                  data={institutes}
                  field={'name'}
                  type={'obj'}
                  required
                  handleItemClick={(x) => {
                    selectInstitution(x._id);
                  }}
                  margin={'mt-2'}
                />
              </div>  
            ) : <div className="my-4 w-full relative font-semibold "> </div> } 
            { isOwner ? 
              <div className="my-4 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Select Owner or Director <span className="text-red-500">*</span>
                </label>
                <DropDown
                  data={['Owner', 'Director']}
                  handleItemClick={(x) => {
                    handleInputChange({ target: { value: x, name: 'role' } });
                  }}
                  title={Array.isArray(selectedOwner?.currentlyMapped?.role) && selectedOwner?.currentlyMapped?.role.findIndex(e => e == 'dir') > -1 ? "Director" : 'Owner'}
                  width={'w-full'}
                  required
                  otherClasses={'border-borderLight mt-2 text-textDark'}
                />
              </div> :  <div className="my-4 w-full relative font-semibold "> </div>
            }
            </div>

            <div className="items-center mb-4">
              <p className=" font-medium ">{type} Information</p>
            </div>

            <div className="flex w-full">
              <div>
                <div className="profileLogo  bg-gray-500 w-56 h-56 relative">
                  {image && <img src={image} alt=" " className="h-full w-full profileLogo" />}
                  <input hidden ref={imgRef} type="file" onChange={(e) => setProfileImg(e.target.files[0])} />
                  <img
                    src="assets/img/camera.png"
                    className="w-10 h-10 absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2"
                    onClick={(e) => {
                      e.preventDefault();
                      imgRef.current.click();
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-x-4 pb-4 w-full pl-12">
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="category" className="text-textDark font-medium text-sm">
                    Full Name<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    // placeholder="Eg-Rakesh Ahirwar"
                    autoComplete="off"
                    value={formData.full_name}
                    name="full_name"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Email<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required 
                    disabled={selectedOwner?true:false}
                    // placeholder="Eg-32 Years"
                    value={formData.email_id}
                    name="email_id"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Phone<span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    disabled={selectedOwner?true:false}
                    // placeholder="Eg-32 Years"
                    value={formData.phone}
                    name="phone"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Gender
                  </label>
                  <DropDown
                    data={['Male', 'Female']}
                    handleItemClick={(x) => {
                      handleInputChange({ target: { value: x, name: 'gender' } });
                    }}
                    title={selectedOwner?.gender ? selectedOwner.gender : 'Select'}
                    width={'w-full'}
                    otherClasses={'border-borderLight mt-2 text-textDark'}
                  />
                </div>

                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    Date of Birth
                  </label>
                  {/* {selectedOwner ? (
                    <p className="w-full rounded-3xl border font-normal border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur">
                      {moment(formData.dateOfBirth).format('d MMMM YYYY')}
                    </p>
                  ) : (  )}   */}
                    <DatePicker
                      className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none cur"
                      selected={formData.dateOfBirth && formData.dateOfBirth != "" ? moment(formData.dateOfBirth).toDate() : "" }
                      onChange={(date) => setFormData({ ...formData, dateOfBirth: date })}
                      dateFormat={'dd-MM-yyyy'}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                 
                </div>
                <div className="my-2 w-full relative font-semibold ">
                  <label htmlFor="budget" className="text-textDark font-medium text-sm">
                    AADHAR
                  </label>
                  <input
                    type="text"
                    // placeholder="Eg-19 may-1992"
                    value={formData.aadhar}
                    name="aadhar"
                    onChange={handleInputChange}
                    className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className=" font-bold text-black pt-4 px-8">Address</p>
            <div className="grid grid-cols-2 gap-x-4 pb-4 w-full  px-8">
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="category" className="text-textDark font-medium text-sm">
                  Flat No. / House No.
                </label>
                <input
                  type="text"
                  // placeholder="Eg-198"
                  autoComplete="off"
                  value={addressState.hno}
                  name="hno"
                  onChange={handleAddressInputChange}
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Colony / Society
                </label>
                <input
                  type="text"
                  value={addressState.society}
                  name="society"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Amar Colony"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  District<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  value={addressState.district}
                  name="district"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Jalandhar"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  City<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  required
                  value={addressState.city}
                  name="city"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Jalandhar"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  State<span className="text-red-500 ml-1">*</span>
                </label>
                <select
                  type="text"
                  value={addressState.state}
                  name="state"
                  required
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-Punjab"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                >
                  <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                  <option value="Daman and Diu">Daman and Diu</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Ladakh">Ladakh</option>
                  <option value="Lakshadweep">Lakshadweep</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">Meghalaya</option>
                  <option value="Mizoram">Mizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Sikkim">Sikkim</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
              </div>

              <div className="my-2 w-full relative font-semibold">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Pin Code
                </label>
                <input
                  type="text"
                  value={addressState.pincode}
                  name="pincode"
                  onChange={handleAddressInputChange}
                  // placeholder="Eg-141401"
                  className="w-full rounded-3xl border border-borderLight px-4 text-xs mt-2 py-2 focus:outline-none"
                />
              </div>
            </div>

            <div className="flex gap-x-4 justify-end px-8 mb-4">
              {/* <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-tableRowText border-tableRowText focus:outline-none flex justify-center items-center"
                onClick={() => {}}
              >
                Save
              </button> */} 
              {selectedOwner ? <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  //   setStep(2);
                }}
              >
                Update
              </button> :  
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary focus:outline-none flex justify-center items-center"
                onClick={() => {
                  //   setStep(2);
                }}
              >
                Save
              </button>
              }
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateOwner;
