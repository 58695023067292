// const BASE_URL = 'http://localhost:3000/api';
// const Finance_Corn_Job_BASE_URL = 'http://localhost:8030/finCronJob/api';
const BASE_URL = 'https://admin.development.roledin.com/api';
const Finance_Corn_Job_BASE_URL = 'https://development.roledin.com/finCronJob/api';

export const LOGIN = `${BASE_URL}/oauth/token`;

export const LOG_OUT = `${BASE_URL}/logout`;

export const MY_DETAILS = `${BASE_URL}/me`;

export const GET_INSTITUTES = `${BASE_URL}/getAllInstitute`;

export const POST_INSTITUTES = `${BASE_URL}/createInstitution`;

export const UPDATE_INSTITUTE = (id) => `${BASE_URL}/institution/${id}`;

export const UPDATE_INSTITUTE_SESSION = (id) => `${BASE_URL}/updateInstituteSession/${id}`;

export const DELETE_INSTITUTES = (id) => `${BASE_URL}/institute/${id}`;

export const GET_OWNERS_PRINCIPALS = (role) => `${BASE_URL}/getUser/${role}`;

export const POST_OWNER = (instituteId) => `${BASE_URL}/createowner/${instituteId}`;

export const GET_ALL_STUDENTS = (instituteId) => `${BASE_URL}/getAllUserDetail/${instituteId}/student`;

export const GET_ALL_TEACHING_STAFF = (instituteId) => `${BASE_URL}/getAllUserDetail/${instituteId}/teaching`;

export const GET_ALL_NON_TEACHING_STAFF = (instituteId) => `${BASE_URL}/getAllUserDetail/${instituteId}/non-teaching`;

export const CREATE_BULK_USERS = (instituteId, classId, sectionId) =>
  `${BASE_URL}/createAllStudent/${instituteId}/class/${classId}/section/${sectionId}`;

export const UPDATE_USER = (userId) => `${BASE_URL}/${userId}`;

export const DELETE_USER = (userId, roleType) => `${BASE_URL}/userType/${userId}?roleType=${roleType}`;

export const GET_CLASSES = (instituteId) => `${BASE_URL}/getInstituteClass/${instituteId}`;

export const ADD_CLASSES = (instituteId) => `${BASE_URL}/createClass/${instituteId}`;

export const DELETE_CLASSES = (classId) => `${BASE_URL}/class/${classId}`;

export const GET_SECTIONS = (instituteId, classId) => `${BASE_URL}/getSection/${instituteId}/class/${classId}`;

export const ADD_SECTION = (instituteId) => `${BASE_URL}/createSection/${instituteId}`;

export const DELETE_SECTION = (classId, sectionId) => `${BASE_URL}/class/${classId}/section/${sectionId}`;

export const GET_ACTIVE_INCIDENTS = () => `${BASE_URL}/reportIncident`;

export const GET_INACTIVE_INCIDENTS = () => `${BASE_URL}/reportActionIncident`;

export const UPDATE_INCIDENT = (postId) => `${BASE_URL}/reportIncident/${postId}`;

export const ADD_LEAVE_TYPE = (instituteId) => `${BASE_URL}/institute/${instituteId}/leave`;

export const GET_LEAVE_TYPE = `${BASE_URL}/institute/leave`;

export const EDIT_LEAVE_TYPE = (leaveId) => `${BASE_URL}/instituteLeave/${leaveId}`;

export const GET_CREDENTIALS = (instituteId) => `${BASE_URL}/getAllUserName/institute/${instituteId}`;

export const SEND_EMAIL = (instituteId) => `${BASE_URL}/sendMailToClient`;

export const ROLEDIN_FEEDBACKS = (instituteId) => `${BASE_URL}/institute/instituteRemarks`;

export const INSTITUTE_TIMIMG_LOCATION = `${BASE_URL}/institute/shift/time`;

export const UPLOAD_FEED = `${BASE_URL}/adminPost`;

export const GET_FEEDS = `${BASE_URL}/getadminPost`;

export const DELETE_FEED = (postId) => `${BASE_URL}/deleteadminPost/${postId}`;

export const GET_INSTITUTE_FEE_STR = `${BASE_URL}/getALLInstitueFeeStructure`;

export const CREATE_FEE_STRUCTURE = `${BASE_URL}/createInstitueFeeStructure`;

export const UPDATE_FEE_STRUCTURE = (instituteId) => `${BASE_URL}/updateInstitueFeeStructure/${instituteId}`;

export const INSTITUTE_FEE_TYPES = `${BASE_URL}/getFeeStructure`;

export const INSTITUTE_CLASS_SECTIONS = (instituteId) => `${BASE_URL}/getClassSection/institute/${instituteId}`;

export const SAVE_PENDING_FEE = (instituteId, studentId) =>
  `${BASE_URL}/institute/${instituteId}/student/${studentId}/pendingFee`;

export const GET_PENDING_FEE = (instituteId, classId, sectionId) =>
  `${BASE_URL}/institute/${instituteId}/class/${classId}/section/${sectionId}/pendingFee`;

export const HOLIDAYS = `${BASE_URL}/academicHolidays`;

export const MASTER_DATA = `${BASE_URL}/master-data`;

export const RUN_CRON_JOB = `${Finance_Corn_Job_BASE_URL}/runCronJob`;

export const CHANGE_CRON_JOB_STATUS = `${BASE_URL}/activateCronJob`;

// export const AI_MODEL = `${BASE_URL}/institute/AiModels`;

// export const AI_MODEL_POST = `${BASE_URL}/institute/AiModel`;

// export const INSTITUTE_AI_MODEL = (instituteId) => `${BASE_URL}/institute/${instituteId}/AiModel`;

export const INSTITUTE_AI_MODEL_GET = `${BASE_URL}/institute/AiModel`;

// export const INSTITUTE_AI_MODEL_PUT = (modelId) => `${BASE_URL}/model/${modelId}/AiModel`;

export const AI_SERVICES_GET = `${BASE_URL}/AiServices`;

export const AI_Model_GET = `${BASE_URL}/AiModeles`;

"/institute/:instituteId/AiServices"

export const Institute_Services_POST = (instituteId) =>`${BASE_URL}/institute/${instituteId}/AiServices`;

export const Institute_Services_Config_POST = (instituteId) =>`${BASE_URL}/institute/${instituteId}/ConfigServices`;
