import React, { useState, useEffect } from 'react';
import CreatePostWithPicture from '../components/Social/PostImage';
import CreatePostWithVideoComponent from '../components/Social/CreatePostWithVideo';
import { GET_FEEDS } from '../api/urls';
import useApi from '../api/useApi';
import Feeds from '../components/Social/Feeds';

function Social() {
  const [postPicture, setPostPicture] = useState(false);
  const [postVideo, setPostVideo] = useState(false);
  const [feed, setFeed] = useState([]);
  const [socialFeed, getSocialFeed] = useApi();

  useEffect(() => {
    getSocialFeed(GET_FEEDS, 'GET');
  }, []);

  const togglePicture = () => {
    setPostPicture((prev) => !prev);
  };

  const toggleVideo = () => {
    setPostVideo((prev) => !prev);
  };

  const refreshData = () => {
    getSocialFeed(GET_FEEDS, 'GET');
  };

  useEffect(() => {
    if (!socialFeed.isLoading && !socialFeed.isError && socialFeed.data) {
      setFeed(socialFeed.data);
    }
    if (!socialFeed.isLoading && socialFeed.isError) {
      setFeed([]);
    }
  }, [socialFeed.isLoading, socialFeed.isError, socialFeed.data]);

  return (
    <>
      {postPicture && <CreatePostWithPicture handleClose={togglePicture} refreshData={refreshData} />}
      {postVideo && <CreatePostWithVideoComponent handleClose={toggleVideo} refreshData={refreshData} />}

      <div className="w-3/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-4 items-center px-6 py-3">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/3">
              <p>RoledIn Social Posts</p>
            </div>
            <div className="flex gap-x-4 w-2/3 justify-end">
              <button
                className="w-32 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                onClick={() => setPostPicture(true)}
              >
                Image
              </button>
              <button
                className="w-32 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                onClick={() => setPostVideo(true)}
              >
                Video
              </button>
            </div>
          </div>
        </div>

        <Feeds feed2={feed} refreshData={refreshData} />
      </div>
    </>
  );
}

export default Social;
