import React from 'react';

function Search({ placeholder, width, margin, onChange, onEnter }) {
  return (
    <div
      className={`${width} ${margin} border border-tableRowText px-3 py-2 rounded-3xl text-xs  focus:outline-none flex `}
    >
      <img src="assets/img/Search.svg" className=" w-4 mr-2 " alt="" />
      <input
        type="text"
        placeholder={placeholder}
        autoComplete="off"
        // value="Search staff"
        className="text-left w-full  bg-transparent text-textDark  focus:outline-none"
        onChange={(x) => {
          onChange(x.target.value);
        }}
        onKeyDown={(x) => {
          if (x.which === 13) {
            onEnter(x.target.value);
          }
        }}
      />
    </div>
  );
}

export default Search;
