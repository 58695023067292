import React, { useEffect, useState } from 'react';
import { useStateValue } from '../ContextApi/StateProvider';
import {
//  INSTITUTE_AI_MODEL,
//  AI_MODEL_POST,
//  AI_MODEL,
  INSTITUTE_AI_MODEL_GET,
  AI_SERVICES_GET,
  AI_Model_GET,
  Institute_Services_POST,
  Institute_Services_Config_POST,
  // INSTITUTE_AI_MODEL_PUT,
} from '../api/urls';
import useApi from '../api/useApi';

const AIModel = () => {
  const [{ institutes }] = useStateValue();
  // const [openAdd, setOpenAdd] = useState(false);
  // const [assignModel, setAssignModel] = useState();
  const [ShowServices, SetShowServices] = useState(false); 
  const [stateServiceList, setServiceList] = useState([]);
  const [Currinstitute, setCurrinstitute] = useState(null);
  const [ApplyClick, setApplyClick] = useState(false);
  const [renderUI, forceRenderUI] = useState();

  
  const [instituteAiModelRes, instituteAIModel] = useApi();
  const [AllServices, loadAllServices] = useApi();
  const [AllModels, loadAllModel] = useApi();
  const [InstituteServices, loadInstituteServices] = useApi();
  const [ResSaveUpdateConfig, SaveUpdateConfig] = useApi();

  useEffect(() => {
   // getModels(AI_MODEL, 'GET');
    instituteAIModel(INSTITUTE_AI_MODEL_GET, 'GET');
    loadAllServices( AI_SERVICES_GET , 'GET');
    loadAllModel( AI_Model_GET , 'GET');
  }, []);

  useEffect(() => {
    if( !AllServices.isError && !AllServices.isLoading){ 
      if(AllServices.data){
        if(Array.isArray(AllServices.data.ServicesList)) {
          setServiceList(AllServices.data.ServicesList);
        }
      }
    } 
  }, [AllServices]);

  useEffect(() => {
    if( !InstituteServices.isError && !InstituteServices.isLoading && InstituteServices.data && Array.isArray(InstituteServices.data.config) ){
      // console.log("check : ", InstituteServices ); 
      LoadinstituteConfig(InstituteServices.data.config);
    }
  }, [InstituteServices]);


  useEffect(() => {
    // console.log("check : ", ResSaveUpdateConfig ); 
    if(ApplyClick){
      if(ResSaveUpdateConfig.isError){ alert('Error Occured During Configurations.'); }
      else{ alert('Configurations apply successfully'); } 
      setApplyClick(false);
      instituteAIModel(INSTITUTE_AI_MODEL_GET, 'GET');
    } 
  },[ResSaveUpdateConfig]);

  const checkIfModelAssigned = (id) => {
    if (Array.isArray(instituteAiModelRes?.data)) return instituteAiModelRes?.data?.find((x) => x.instituteId === id && x.service != "" );
    else return null;
  };

  const ConfigServices = (instituteID) => {
    SetShowServices(true) ;
    setCurrinstitute(instituteID) ;
    setApplyClick(true);
    loadInstituteServices( Institute_Services_POST(instituteID) , 'POST');
  }
  const handleClose = () => {
    SetShowServices(false);
    setCurrinstitute(null) ; // Currinstitute
    
  };
  const LoadinstituteConfig = (ServiceConfigList) =>{  console.log("--load data : ", ServiceConfigList )
    let loadedServices = stateServiceList.map((eachService, idx) => {
      let mapping =  ServiceConfigList.filter((eachConfigService, ConfigIndex ) => {
        if(eachConfigService.ID == eachService.ID){
          return eachConfigService ; 
        }
      })
      // console.log("-- mapping : ", mapping ); 
      if( mapping && mapping.length > 0 ){ if( mapping[0] ){ return {...mapping[0], Name:eachService.Name } ;  } 
      }   
      // console.log("-- mapping empty ret : ", eachService ); 
      // return eachService ; 
      return {...eachService , model : "", token : "" , limit:"" } ;
    })
    console.log("-- loadedServices : ", loadedServices ); 
    setServiceList(loadedServices);
  } 
  const ReRenderUI=() => { forceRenderUI(renderUI ? false : true ); }
  const handleModelChange = (ServiceId,ModelID) => {
    // console.log(" stateServiceList 1 : ", stateServiceList );
    // console.log("Service / model ", ServiceId,ModelID ,"\n", stateServiceList );
    let ServiceIndex = stateServiceList.map(function(e) { return e.ID; }).indexOf(ServiceId);// console.log("Service index ", ServiceIndex );
    if(ServiceIndex > -1){
      stateServiceList[ServiceIndex].model = ModelID ;
      setServiceList(stateServiceList);
      // console.log("updating state : ", stateServiceList[ServiceIndex] );
    } 
    ReRenderUI();
  }
  const handleTokenChange = (ServiceId,token) =>{
    // console.log(" stateServiceList 2 : ", stateServiceList );
    let ServiceIndex = stateServiceList.map(function(e) { return e.ID; }).indexOf(ServiceId); // console.log("Service index ", ServiceIndex );
    if(ServiceIndex > -1){
      stateServiceList[ServiceIndex].token = token ;
      setServiceList(stateServiceList);
    }
    ReRenderUI();
  }
  const handleLimtChange = (ServiceId,limit) =>{
    // console.log(" stateServiceList 3 : ", stateServiceList );
    let ServiceIndex = stateServiceList.map(function(e) { return e.ID; }).indexOf(ServiceId); // console.log("Service index ", ServiceIndex );
    if(ServiceIndex > -1){
      stateServiceList[ServiceIndex].limit = limit ;
      setServiceList(stateServiceList);
    }
    ReRenderUI();
  }
  const ApplyAiConfig = () => {
    /// console.log("save services config here");
    handleClose();
    SaveUpdateConfig( Institute_Services_Config_POST(Currinstitute) , 'POST',{ servicelist : stateServiceList}); 
  }

  let ModelList = [];
  if( !AllModels.isError && !AllModels.isLoading){ 
    if(AllModels.data){
      if(Array.isArray(AllModels.data.ModelList))
      ModelList = AllModels.data.ModelList;
    }
  }
  return (
    <div className="w-4/5 pb-10">

      { ShowServices && (
          <div
          className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
          style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
        >
          <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
            <div className="flex justify-between py-2 border-b border-borderYellow items-center">
              <span className="font-bold px-4 py-3 text-textBlue1">Configure AI Model and Service</span>
              <div className="flex gap-x-8 items-center relative justify-end">
                <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
              </div>
            </div>

            <div className="flex flex-wrap w-full h-1/4 px-6 pb-6">
              <div className="w-full py-4">
                <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                  <div className="w-full flex bg-tableHeader rounded-3xl">
                    <div className="flex w-full relative text-left px-4 text-xs font-medium">
                      <div className="w-1/6 border-r-2 py-2 px-2">Service Name</div> 
                      <div className="w-2/6 border-r-2 py-2 px-2">Model</div>
                      <div className="w-2/6 border-r-2 py-2 px-2">Token</div>
                      <div className="w-1/6 py-2 px-2">User Limit</div>
                    </div>
                  </div>
                  <div className="customScrollBar" style={{ maxHeight: '75vh' }}>
                  {Array.isArray(stateServiceList) &&
                    stateServiceList.map((x, idx) => {
                      return (
                        <div key={idx} className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                              stateServiceList.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-1/6 px-2 text-tableRowText">{x?.Name}</div>
                            <div className="w-2/6 px-2 text-tableRowText">
                              <select
                                name="model"
                                // placeholder="All"
                                value={x.model?x.model:""}
                                onChange={(event)=>{handleModelChange(x.ID, event.target.value); } }
                                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                              >
                                <option value="NoSelect">Select Model</option>
                                {ModelList.map((AiModel) => (
                                  Array.isArray(AiModel.Services) && AiModel.Services.includes(x.ID) ?
                                  <option key={AiModel.ID} value={AiModel.ID}>
                                    {AiModel.Name}
                                  </option> : null
                                ))}
                              </select>
                            </div>
                            <div className="w-2/6 px-2 text-tableRowText">
                              <input
                                type="text"
                                name="model"
                                // placeholder="All"
                                value={x.token?x.token:""}
                                onChange={(event)=>{handleTokenChange(x.ID, event.target.value); } }
                                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                              />
                            </div>
                            <div className="w-1/6 px-2 text-tableRowText">
                              <input
                                type="text"
                                name="model"
                                // placeholder="All"
                                value={x.limit?x.limit:""}
                                onChange={(event)=>{handleLimtChange(x.ID, event.target.value); } }
                                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  </div>
                </div>
              </div>
              <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
                  {/* {aiModelRes.isLoading ? (
                    <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                  ) : ( */}
                  <button
                    // type="submit"
                    onClick={() => ApplyAiConfig()}
                    className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                  >
                    Apply
                  </button>
                {/* )} */}
              </div>
            </div> 
            
          </div>
        </div>
      )}
      <div className="w-full bg-white rounded-xl h-auto mb-4">
        <div className="items-center px-6 py-3 border-b border-borderYellow">
          <div className="flex justify-between items-center">
            <div className="font-bold w-1/3">
              <p>AL Models</p>
            </div>
            {/* <div className="flex gap-x-4 w-2/3 justify-end">
              <button
                className="w-32 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                onClick={() => handleAddModel()}
              >
                Add New Model
              </button>
            </div> */}
          </div>
        </div>
        <div className="flex flex-wrap w-full h-1/4 px-6">
          <div className="w-full py-4">
            <div className="bg-white m-auto border rounded-2xl border-borderYellow">
              <div className="w-full flex bg-tableHeader rounded-3xl">
                <div className="flex w-full relative text-left px-4 text-xs font-medium">
                  <div className="w-2/6 border-r-2 py-2 px-2">Name</div>
                  {/* <div className="w-1/6 border-r-2 py-2 px-2">Model</div> */}
                  <div className="w-3/6 border-r-2 py-2 px-2">Service/Model</div>
                  <div className="w-1/6 py-2 px-2">Action</div>
                </div>
              </div>
              <div className="customScrollBar" style={{ maxHeight: '75vh' }}>
                {Array.isArray(institutes) &&
                  institutes.map((x, idx) => {
                    return (
                      <div key={x._id} className="w-full">
                        <div
                          className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                            institutes.length - 1 !== idx && 'border-b border-borderYellow'
                          }`}
                        >
                          <div className="w-2/6 px-2 text-tableRowText ">{x?.name}</div>
                          {/* <div className="w-1/6 px-2 text-tableRowText ">
                            {checkIfModelAssigned(x._id)?.model || '--'}
                          </div> */}
                          <div className="w-3/6 px-2 text-tableRowText ">
                            {checkIfModelAssigned(x._id)?.service || '--'}
                          </div>

                          <div className={`w-1/6 gap-x-3 flex`}>
                            <button
                              className={`px-3 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  ${
                                !checkIfModelAssigned(x._id)
                                  ? 'bg-primary border-primary text-white'
                                  : 'bg-white border-primary text-primary'
                              }`}
                              onClick={() => {
                                ConfigServices(x._id);
                              }}
                            >
                              {!checkIfModelAssigned(x._id) ? 'Enable Services' : 'Edit Services'}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIModel;
