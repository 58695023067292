import React, { useEffect, useState } from 'react';
import IncidetReported from '../components/Incident/IncidetReported';
import ViewIncident from '../components/Incident/ViewIncident';
import InActiveIncidents from '../components/Incident/InActiveIncidents';

import useApi from '../api/useApi';
import { GET_INACTIVE_INCIDENTS, GET_ACTIVE_INCIDENTS } from '../api/urls';

function Incident() {
  const [open, setOpen] = useState(false);
  const [selectedIncident, selectIncident] = useState();
  const [incidents, setIncidents] = useState([]);

  const [{ isLoading, isError, data }, getIncidents] = useApi();
  const [inactiveIncidentRes, getInActiveIncidents] = useApi();

  useEffect(() => {
    if (selectedIncident) {
      toggleView();
    }
  }, [selectedIncident]);

  useEffect(() => {
    getIncidents(GET_ACTIVE_INCIDENTS, 'GET');
    getInActiveIncidents(GET_INACTIVE_INCIDENTS, 'GET');
  }, []);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      setIncidents(data);
    }
  }, [isLoading, isError, data]);

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    selectIncident(null);
    toggleView();
  };

  const refreshList = () => {
    getIncidents(GET_ACTIVE_INCIDENTS, 'GET');
    getInActiveIncidents(GET_INACTIVE_INCIDENTS, 'GET');
  };

  return (
    <div className="w-4/5 pb-10">
      {open && <ViewIncident handleClose={handleClose} selectedIncident={selectedIncident} refreshList={refreshList} />}

      {isLoading ? (
        <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
      ) : (
        <IncidetReported selectIncident={selectIncident} data={incidents} title={'Active Incidents'} />
      )}

      {inactiveIncidentRes.isLoading ? (
        <img src="assets/img/loader.svg" className="w-14 m-auto mt-10" alt="" />
      ) : (
        <InActiveIncidents selectIncident={selectIncident} data={inactiveIncidentRes} title={'InActive Incidents'} />
      )}
    </div>
  );
}

export default Incident;
