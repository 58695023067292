import React from 'react';
import { CSVLink } from 'react-csv';

function ExcelExportBtn({ data, fileName, text, handleClick, headers }) {
  return (
    <CSVLink
      className="w-40 border py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
      data={data}
      filename={fileName}
      asyncOnClick={true}
      onClick={handleClick}
      headers={headers}
    >
      {text || 'Export to Excel'}
    </CSVLink>
  );
}

export default ExcelExportBtn;
