import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

import useApi from '../api/useApi';
import { LOGIN } from '../api/urls';
import { useStateValue } from '../ContextApi/StateProvider';

const Login = () => {
  const [stats, setStats] = useState('login');
  const [prevCard, setPrevCard] = useState('login');
  const [displayPwd, setDisplayPwd] = useState(false);

  const history = useHistory();

  const [{ token }, dispatch] = useStateValue();

  const [{ isLoading, isError, data }, login] = useApi();

  const formModel = {
    username: '',
    password: '',
    grant_type: 'password',
  };

  const [formData, setFormData] = useState(formModel);

  const handleInputChange = useCallback((e) => setFormData({ ...formData, [e.target.name]: e.target.value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    login(LOGIN, 'POST', new URLSearchParams(formData));
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      if (data.expires_in) {
        const expires = new Date().getTime() + data.expires_in * 1000;
        Cookies.set('auth', JSON.stringify(data), { expires: new Date(expires) });
        localStorage.refresh_token = data.refresh_token;
        dispatch({
          type: 'TOKEN',
          token: { ...data, expires_in: expires },
        });

        history.push('/institution');
      } else {
        alert('Something went wrong... PLease try again!');
      }
    }

    if (!isLoading && isError) {
      alert('Either Username or Password is incorrect');
      console.log('error');
    }
  }, [isLoading, isError, data]);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      {/* <div className="text-center text-4xl p-4">Whats your Role today?</div> */}
      {/* <div className="w-11/12 h-32 flex bg-white text-center m-auto  rounded-3xl shadow-2xl">
        <img
          src="assets/img/back.svg"
          className="w-10 h-8 m-12"
          alt=""
          onClick={() => {
            if (prevCard === 'login') history.goBack();
            else setStats(prevCard);
          }}
        />
        <div className="flex-1 relative">
          <img src="assets/img/roledin.svg" className="w-auto m-auto h-32 pr-10" alt="" />
          <img
            src="assets/img/Group.svg"
            className="absolute w-9 top-11 left-8 sm:w-12 sm:top-10 sm:left-11 md:w-16 md:left-16 md:top-8 lg:w-16 lg:top-8 lg:left-80"
            alt=""
            style={{ left: '31.7%' }}
          />
        </div>
      </div> */}
      <div className="relative mt-32">
        {/* <img
          src="assets/img/Frame.svg"
          className="absolute mx-auto left-1/2 transform -translate-x-1/2"
          style={{ height: '100%' }}
        /> */}
        {stats === 'login' && (
          <form onSubmit={handleSubmit}>
            <div className="rectangle-10">
              <img src="assets/img/Group.svg" className="iconImage" alt="" />
              <input
                type="text"
                className="inputBox"
                value={formData.username}
                name="username"
                onChange={handleInputChange}
                placeholder="Username"
              />
              <div className="relative">
                {displayPwd ? (
                  <img
                    src="assets/img/HidePassword.svg"
                    className="absolute right-8 cursor-pointer top-4 w-5"
                    onClick={() => setDisplayPwd((prev) => !prev)}
                  />
                ) : (
                  <img
                    src="assets/img/ShowPassword.svg"
                    className="absolute right-8 cursor-pointer top-4 w-5"
                    onClick={() => setDisplayPwd((prev) => !prev)}
                  />
                )}
                <input
                  type={displayPwd ? 'text' : 'password'}
                  value={formData.password}
                  name="password"
                  onChange={handleInputChange}
                  className="inputBox"
                  placeholder="Password"
                />
              </div>
              {/* <p className="text-sm px-4 mb-8 cursor-pointer" style={{ color: '#767676' }}>
                Remember Me
              </p> */}
              <input type="submit" className="loginBtn cursor-pointer" value="LOGIN" />
              {/* <p
                className="text-sm px-4 pt-4 cursor-pointer"
                style={{ color: '#767676' }}
                // onClick={() => {
                //   setStats('forgot');
                //   setPrevCard('login');
                // }}
              >
                Forgot Password?
              </p> */}
            </div>
          </form>
        )}
        {stats === 'forgot' && (
          <div className="rectangle-10">
            <img src="assets/img/Group.svg" className="iconImage" alt="" />
            <p className="text-lg text-center mb-12" style={{ color: '#767676' }}>
              Forgot Password?
            </p>

            <input
              type="submit"
              className="loginBtn"
              style={{ margin: '0 auto 3em' }}
              value="Generate OTP"
              onClick={() => {
                setStats('generateOtp');
                setPrevCard('forgot');
              }}
            />
            <input type="submit" className="loginBtn" value="I have an OTP" onClick={() => setStats('iHaveAnOtp')} />
          </div>
        )}

        {stats === 'generateOtp' && (
          <div className="rectangle-10">
            <img src="assets/img/Group.svg" className="iconImage" alt="" />

            <input
              type="text"
              className="inputBox"
              style={{ margin: '2em auto 0' }}
              placeholder="RoledIn Id /Phone No/ Email Id"
            />
            <input
              type="submit"
              onClick={() => {
                setStats('otpSent');
                setPrevCard('generateOtp');
              }}
              className="loginBtn"
              style={{ margin: '3em auto 0' }}
              value="Send OTP"
            />
          </div>
        )}

        {stats === 'otpSent' && (
          <div className="rectangle-10">
            <img src="assets/img/Group.svg" className="iconImage" alt="" />
            <p className="text-lg text-center mb-4 font-bold" style={{ color: '#767676' }}>
              OTP has been sent to
              <br /> your email/phone
            </p>

            <p className="text-lg text-center mb-4" style={{ color: '#767676' }}>
              Resend OTP?
            </p>
            <input type="text" className="inputBox text-center" style={{ margin: '0 auto 0' }} placeholder="OTP" />
            <input
              type="submit"
              onClick={() => {
                setStats('resetPassword');
                setPrevCard('otpSent');
              }}
              className="loginBtn"
              style={{ margin: '2em auto 0' }}
              value="Reset Password"
            />
          </div>
        )}
        {stats === 'resetPassword' && (
          <div className="rectangle-10">
            <img src="assets/img/Group.svg" className="iconImage" alt="" />

            <input type="text" className="inputBox" style={{ margin: '2em auto 3em' }} placeholder="New Password" />
            <div className="relative">
              <img src="assets/img/eye.png" className="eyeImage" alt="" />
              <input type="password" className="inputBox " placeholder="Re-enter Password" />
            </div>
            <input
              type="submit"
              onClick={() => {
                setPrevCard('resetPassword');
              }}
              className="loginBtn"
              style={{ margin: '3em auto 0' }}
              value="Confirm"
            />
          </div>
        )}
        {stats === 'iHaveAnOtp' && (
          <div className="rectangle-10">
            <img src="assets/img/Group.svg" className="iconImage" alt="" />
            <p className="text-lg text-center font-bold" style={{ color: '#767676' }}>
              Kindly Enter OTP
            </p>

            <p className="text-lg text-center mb-4" style={{ color: '#767676' }}>
              Resend OTP?
            </p>
            <input type="text" className="inputBox" style={{ margin: '0 auto 1em' }} placeholder="RoledIN ID" />
            <input type="text" className="inputBox text-center" placeholder="OTP" />
            <input
              type="submit"
              onClick={() => {
                setStats('resetPassword');
              }}
              className="loginBtn"
              style={{ margin: '2em auto 0' }}
              value="Reset Password"
            />
          </div>
        )}
        {stats === 'contactDetailNotFound' && (
          <div className="rectangle-10">
            <img src="assets/img/Group.svg" className="iconImage" style={{ margin: '6em auto 0' }} alt="" />
            <p className="text-lg text-center" style={{ color: '#767676' }}>
              Contact Details not found.
              <br /> OTP sent to auth_role
            </p>
          </div>
        )}
      </div>

      {/* <div className="footerText">
        <p>
          Home | About | Products | Contact Us | Privacy Policy
          <br /> Copyright © 2020 roledin.in All Right Reserved
        </p>
      </div> */}
    </div>
  );
};

export default Login;
