const STAFF_CSV_TEMPLATE = [
  'Name',
  'Email',
  'Contact',
  'Gender',
  'MaritalStatus',
  'DateOfBirth',
  // 'Aadhar',
  'PERMANENT_Address_Hno',
  'PERMANENT_Address_Society',
  'PERMANENT_Address_District',
  'PERMANENT_Address_City',
  'PERMANENT_Address_State',
  'PERMANENT_Address_Pincode',
  'Correspondance_Address_Hno',
  'Correspondance_Address_Society',
  'Correspondance_Address_District',
  'Correspondance_Address_City',
  'Correspondance_Address_State',
  'Correspondance_Address_Pincode',
  'Experience_Title',
  'Experience_Type',
  'Experience_CompanyName',
  'Experience_Location',
  'Experience_StartDate',
  'Experience_EndDate',
  'Education_University',
  'Education_Degree',
  'Education_FieldOfStudy',
  'Education_Grade',
  'Education_StartDate',
  'Education_EndDate',
  'JobProfile_Title',
  'JobProfile_Supervisor',
  'JobProfile_StartDate',
  'JobProfile_Salary',
  'BankName',
  'AccountNo',
  'ifsc',
];

export default STAFF_CSV_TEMPLATE;
