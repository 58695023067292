import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { useStateValue } from '../ContextApi/StateProvider';
import { LOGIN } from './urls';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const apiReducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
        status: action.status,
      };
    case 'FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        status: action.status,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

const useApi = () => {
  const history = useHistory();
  // const BASE_URL = 'https://roledin.com/api/';
  // const BASE_URL = 'http://localhost:';

  const [url, setUrl] = useState('');
  const [data, setData] = useState(null);
  const [method, setMethod] = useState('');
  const [apiHeaders, setHeaders] = useState({});
  const [{ token }, dispatch2] = useStateValue();

  // const currentTime = new Date().getTime();

  const x = Cookies.get('auth');

  // if (!x) {
  //   // const tokenExpired = currentTime > token.expires_in;
  //   // console.log(currentTime, token.expires_in, tokenExpired);
  //   // if (tokenExpired) {
  //   axios({
  //     method: 'POST',
  //     url: LOGIN,
  //     data: new URLSearchParams({ grant_type: 'refresh_token', refresh_token: localStorage.refresh_token }),
  //     headers: {
  //       Authorization: 'Basic Um9sZWRJbjpSb2xlZEluIzEyMw==',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   })
  //     .then((data) => {
  //       if (data.refresh_token) {
  //         const expires = new Date(new Date().getTime() + data.expires_in * 1000);
  //         Cookies.set('auth', JSON.stringify(data), { expires });
  //         localStorage.refresh_token = data.refresh_token;
  //         dispatch2({
  //           type: 'TOKEN',
  //           token: { ...data, expires_in: expires },
  //         });
  //         console.log('if');
  //       } else {
  //         history.replace('/');
  //         dispatch2({
  //           type: 'TOKEN',
  //           token: null,
  //         });
  //         console.log('esl');
  //       }
  //     })
  //     .catch((err) => {
  //       history.replace('/');
  //       console.log('ff');
  //       dispatch2({
  //         type: 'TOKEN',
  //         token: null,
  //       });
  //     });
  //   // }
  // }

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    isError: false,
    data: data,
    status: '',
  });

  const setApiRequest = (url, method, data = {}, headers) => {
    setMethod(method);
    setData(data);
    setUrl(url);
    setHeaders(headers);
  };

  useEffect(() => {
    let didCancel = false;

    const callApi = async () => {
      dispatch({ type: 'INIT' });
      try {
        const result = await axios({
          method,
          url,
          data,
          headers:
            url.includes('oauth/token') && !token
              ? {
                  Authorization: 'Basic Um9sZWRJbjpSb2xlZEluIzEyMw==',
                  'Content-Type': 'application/x-www-form-urlencoded',
                }
              : {
                  Authorization: `Bearer ${token.access_token}`,
                  'Content-Type': apiHeaders ? 'multipart/form-data' : 'application/json',
                },
        });
        if (!didCancel) {
          console.log('URL:' + url + ' Result ', result);
          dispatch({ type: 'SUCCESS', payload: result.data, status: result.status });
        }
      } catch (error) {
        console.log(error?.response?.data);
        if (!didCancel) {
          dispatch({
            type: 'FAILURE',
            payload: error?.response && error?.response?.data ? error?.response?.data : '',
            status: error?.response?.status,
          });
        }
      }
      setMethod('');
      setData(null);
      setUrl('');
    };

    if (url && method) callApi();

    return () => {
      didCancel = true;
    };
  }, [url, method, data]);

  return [state, setApiRequest];
};

export default useApi;
