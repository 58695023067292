import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  CreatePOstWithPicture,
  Divider,
  FlexWrapperOne,
  FlexWrapperEight,
  CreatePostLabel,
  CloseWindowIcon,
  Vector,
  DividerTwo,
  PortText,
  FlexWrapperSix,
  CancelPicture,
  CloseIcon,
  FlexWrapperSeven,
  NoOfPictures,
  FlexWrapperTwo,
  FlexWrapperTwoDiv,
  PostLabel,
  CreatePostWrapper,
  UploadImg,
} from './PostImageStyle';
// import '../../../scrollbar.css';
import useApi from '../../api/useApi';
import { UPLOAD_FEED } from '../../api/urls';

const CreatePostWithPicture = ({ handleClose, refreshData }) => {
  const uploadImageRef = useRef();
  const [postData, setPostData] = useState({
    summary: '',
    images: {},
  });
  const [createPost, setCreatePost] = useApi();
  const handleInputChange = useCallback((e) => setPostData({ ...postData, [e.target.name]: e.target.value }));
  const [uploadImage, setImage] = useState(null);

  const handleFileChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setPostData({ ...postData, images: event.target.files });
  };

  const postFeed = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (var i = 0; i < postData.images.length; i++) {
      formData.append('images', postData.images[i]);
    }

    formData.append('summary', postData.summary);
    setCreatePost(UPLOAD_FEED, 'POST', formData);
  };

  useEffect(() => {
    if (!createPost.isLoading && !createPost.isError && createPost.status === 201) {
      refreshData();
      handleClose();
    }
  }, [createPost]);

  return (
    <CreatePostWrapper className="customScrollBar">
      <CreatePOstWithPicture onSubmit={postFeed}>
        <Divider alt="" src="https://static.overlay-tech.com/assets/48dc492e-a7f8-4964-9cf0-5ef324424ed4.svg" />
        <FlexWrapperOne>
          <FlexWrapperEight>
            <CreatePostLabel>Create Post</CreatePostLabel>
            <CloseWindowIcon onClick={() => handleClose()}>
              <Vector alt="" src="https://static.overlay-tech.com/assets/73bdfdb8-c9a9-4200-a2bf-6e976f1b12ed.svg" />
            </CloseWindowIcon>
          </FlexWrapperEight>
          <DividerTwo />
          <PortText
            placeholder="Write your post here..."
            type="text"
            rows={5}
            name="summary"
            className="postText"
            onChange={handleInputChange}
          />
          {uploadImage === null && (
            <>
              <UploadImg
                hidden
                ref={uploadImageRef}
                type="file"
                multiple
                name="images"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileChange}
              />

              <button
                className="rounded-full bg-primary border-primary text-white text-sm py-2  mx-auto my-10 w-11/12"
                onClick={(e) => {
                  e.preventDefault();
                  uploadImageRef.current.click();
                }}
              >
                Select Image from device
              </button>
            </>
          )}
          {uploadImage !== null && (
            <FlexWrapperSix url={uploadImage}>
              <CancelPicture>
                <CloseIcon
                  onClick={() => {
                    setImage(() => null);
                    setPostData({ ...postData, images: '' });
                  }}
                >
                  <Vector
                    alt=""
                    src="https://static.overlay-tech.com/assets/a02e5622-1943-456c-9042-e1e98bbc3da5.svg"
                  />
                </CloseIcon>
              </CancelPicture>
              <FlexWrapperSeven>
                <NoOfPictures>{`${postData.images.length} Photos`}</NoOfPictures>
              </FlexWrapperSeven>
            </FlexWrapperSix>
          )}
          {/* <FlexWrapperNine>
            <SelectSchoolLabel>Select School</SelectSchoolLabel>
            <SelectMultipleAffordance>(Select Multiple)</SelectMultipleAffordance>
          </FlexWrapperNine>
          <div className="pl-10">
            {institutes.map((x) => (
              <div key={x?._id} className="mb-2 flex items-center">
                <input type="checkbox" onChange={handleInputChange} className="mr-3" />
                <span className="text-base" style={{ color: '#212121B8' }}>
                  {x?.name}
                </span>
              </div>
            ))}
          </div> */}

          <FlexWrapperTwoDiv>
            {createPost.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto mr-6" alt="" />
            ) : (
              <FlexWrapperTwo>
                <PostLabel>Post</PostLabel>
              </FlexWrapperTwo>
            )}
          </FlexWrapperTwoDiv>
        </FlexWrapperOne>
      </CreatePOstWithPicture>
    </CreatePostWrapper>
  );
};

export default CreatePostWithPicture;
