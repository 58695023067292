import React, { useEffect, useState } from 'react';
import useApi from '../api/useApi';
import { GET_INSTITUTE_FEE_STR, INSTITUTE_FEE_TYPES } from '../api/urls';
import { useStateValue } from '../ContextApi/StateProvider';
import AddFeeStructure from '../components/FeeStructure/Add';
import EditFeeStructure from '../components/FeeStructure/Edit';

function FeeStructure() {
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedInstitution, selectInstitution] = useState();

  const [instituteFeeRes, getInstituteFee] = useApi();
  const [feeTypeRes, getFeeTypes] = useApi();
  //   const [instituteRes, getInstitutes] = useApi();

  const [{ institutes }, dispatch] = useStateValue();

  useEffect(() => {
    getInstituteFee(GET_INSTITUTE_FEE_STR, 'GET');
    getFeeTypes(INSTITUTE_FEE_TYPES, 'GET');
  }, []);

  const refreshList = () => {
    getInstituteFee(GET_INSTITUTE_FEE_STR, 'GET');
    // getInstitutes(GET_INSTITUTES, 'GET');
  };

  //   useEffect(() => {
  //     if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data && instituteRes.data.response) {
  //       dispatch({
  //         type: 'INSTITUTE',
  //         institutes: instituteRes.data.response,
  //       });
  //     }
  //   }, [instituteRes]);

  return (
    <>
      {openAdd && (
        <AddFeeStructure
          handleClose={() => {
            setOpenAdd(false);
          }}
          refreshList={refreshList}
          institutes={institutes}
          feeTypes={feeTypeRes?.data?.feeStructure?.feeStructureList || []}
        />
      )}

      {selectedInstitution && (
        <EditFeeStructure
          handleClose={() => {
            selectInstitution(null);
          }}
          selectedInstitution={selectedInstitution}
          refreshList={refreshList}
          feeTypes={feeTypeRes?.data?.feeStructure?.feeStructureList || []}
        />
      )}

      <div className="w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-4">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="flex justify-between items-center">
              <div className="font-bold w-1/3">
                <p>Institute Fee Structure</p>
              </div>
              <div className="flex gap-x-4 w-2/3 justify-end">
                <button
                  className="w-32 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                  onClick={() => setOpenAdd(true)}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4 px-6">
            <div className="w-full py-4">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-2/6 border-r-2 py-2 px-2">Name</div>
                    <div className="w-2/6 border-r-2 py-2 px-2">Type</div>
                    <div className="w-2/6 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ maxHeight: '75vh' }}>
                  {Array.isArray(instituteFeeRes?.data?.feeStructureList) ? (
                    instituteFeeRes?.data?.feeStructureList.map((x, idx) => {
                      return (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                              instituteFeeRes?.data?.feeStructureList.length - 1 !== idx &&
                              'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-2/6 px-2 text-tableRowText ">{x?.instituteId?.name}</div>
                            <div className="w-2/6 px-2 text-tableRowText ">{x?.feeStructure.join(', ')}</div>
                            <div className={`w-2/6 gap-x-3 flex`}>
                              <button
                                className="px-6 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                onClick={() => {
                                  selectInstitution(x);
                                }}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-lg text-center my-10">No Record found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeeStructure;
