import React, { useEffect, useState } from 'react';
import useApi from '../api/useApi';
import { GET_INSTITUTES, DELETE_INSTITUTES } from '../api/urls';
import CreateInstitution from '../components/Institution/CreateInstitution';
import ShiftGeoLocationDetails from '../components/Institution/ShiftGeoLocationDetails';
import AddSession from '../components/Institution/AddSession';
import { useStateValue } from '../ContextApi/StateProvider';

function Home() {
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedInstitution, selectInstitution] = useState();
  const [openLocation, setOpenLocation] = useState(false);
  const [openSession, setOpenSession] = useState(false);
  const [{ institutes, user }, dispatch] = useStateValue();

  const [instituteRes, getInstitutes] = useApi();
  const [deleteRes, deleteInstitutes] = useApi();

  useEffect(() => {
    getInstitutes(GET_INSTITUTES, 'GET');
  }, []);

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data && instituteRes.data.response) {
      dispatch({
        type: 'INSTITUTES',
        institutes: instituteRes.data.response,
      });
    }
  }, [instituteRes]);

  const refreshList = () => {
    getInstitutes(GET_INSTITUTES, 'GET');
  };

  const handleDelete = (x) => {
    const z = confirm('Are you sure you want to delete?');

    if (z) deleteInstitutes(DELETE_INSTITUTES(x._id), 'DELETE');
  };

  const handleAddSession = (x) => {
    setOpenSession(x);
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status == 200) {
      refreshList();
      alert('SUCCESS');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert('FAILED');
    }
  }, [deleteRes]);

  return (
    <>
      {openAdd && (
        <CreateInstitution
          handleClose={() => {
            setOpenAdd(false);
            selectInstitution(null);
          }}
          refreshList={refreshList}
          selectedInstitution={selectedInstitution}
        />
      )}
      {openLocation && (
        <ShiftGeoLocationDetails
          handleClose={() => {
            setOpenLocation(false);
            selectInstitution(null);
          }}
          refreshList={refreshList}
          selectedInstitution={selectedInstitution}
        />
      )}

      {openSession && (
        <AddSession
          handleClose={(refresh = false) => {
            setOpenSession(null);
            if (refresh) {
              refreshList();
            }
          }}
          selectedInstitution={openSession}
        />
      )}

      <div className="w-4/5 pb-10">
        <div className="w-full bg-white rounded-xl h-auto mb-4">
          <div className="items-center px-6 py-3 border-b border-borderYellow">
            <div className="flex justify-between items-center">
              <div className="font-bold w-1/3">
                <p>Institution</p>
              </div>
              <div className="flex gap-x-4 w-2/3 justify-end">
                <button
                  className="w-32 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                  onClick={() => setOpenAdd(true)}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap w-full h-1/4 px-6">
            <div className="w-full py-4">
              <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                <div className="w-full flex bg-tableHeader rounded-3xl">
                  <div className="flex w-full relative text-left px-4 text-xs font-medium">
                    <div className="w-1/6 border-r-2 py-2 px-2">Name</div>
                    <div className="w-1/6 border-r-2 py-2 px-2">Type</div>
                    {/* <div className="w-1/6 border-r-2 py-2 px-2">Address</div> */}
                    <div className="w-1/6 border-r-2 py-2 px-2">Phone</div>
                    <div className="w-3/6 py-2 px-2">Action</div>
                  </div>
                </div>
                <div className="customScrollBar" style={{ maxHeight: '75vh' }}>
                  {Array.isArray(institutes) &&
                    institutes.map((x, idx) => {
                      return (
                        <div className="w-full">
                          <div
                            className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                              institutes.length - 1 !== idx && 'border-b border-borderYellow'
                            }`}
                          >
                            <div className="w-1/6 px-2 text-tableRowText ">{x?.name}</div>
                            <div className="w-1/6 px-2 text-tableRowText ">{x?.type}</div>
                            {/* <div className="w-1/6 px-2 text-tableRowText ">{x?.address}</div> */}
                            <div className="w-1/6 px-2 text-tableRowText ">{x?.phone}</div>

                            <div className={`w-3/6 gap-x-3 flex`}>
                              {/* <button
                                className="w-1/4 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                onClick={() => {
                                  setOpenAdd(true);
                                  selectInstitution(x);
                                }}
                              >
                                View
                              </button> */}
                              <img
                                src="assets/img/view.svg"
                                className="cursor-pointer top-4"
                                onClick={() => {
                                  setOpenAdd(true);
                                  selectInstitution(x);
                                }}
                              />

                              {user?.role === 'superadmin' && (
                                <img
                                  src="assets/img/delete.svg"
                                  className="cursor-pointer top-4"
                                  onClick={() => {
                                    handleDelete(x);
                                  }}
                                />
                              )}
                              <button
                                className="px-3 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  bg-primary border-primary text-white"
                                onClick={() => {
                                  setOpenLocation(true);
                                  selectInstitution(x);
                                }}
                              >
                                Location/Timing
                              </button>
                              {/* <button
                                className="w-1/4 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                onClick={() => handleDelete(x)}
                              >
                                Delete
                              </button> */}
                              <button
                                className="px-3 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  bg-primary border-primary text-white"
                                onClick={() => handleAddSession(x)}
                              >
                                Add Session
                              </button>
                              <button
                                className="px-3 border py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center  bg-primary border-primary text-white"
                                onClick={() => handleAddSession({ ...x, isEdit: true })}
                              >
                                Edit Session
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
