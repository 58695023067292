import React, { useState, useEffect } from 'react';
import AddLeave from '../components/InstituteLeaves/AddLeave';
import { GET_LEAVE_TYPE, EDIT_LEAVE_TYPE } from '../api/urls';
import useApi from '../api/useApi';

function InstituteLeaves() {
  const [openAdd, setOpenAdd] = useState(false);
  const [institute, selectInstitute] = useState(false);
  const [selectedLeave, selectLeave] = useState();

  const [{ isLoading, isError, data }, getLeaveTypes] = useApi();
  const [deleteRes, deleteLeave] = useApi();

  useEffect(() => {
    getLeaveTypes(GET_LEAVE_TYPE, 'GET');
  }, []);

  const refreshList = () => {
    getLeaveTypes(GET_LEAVE_TYPE, 'GET');
  };

  const handleDelete = (x) => {
    const z = confirm('Are you sure you want to delete?');

    if (z) deleteLeave(EDIT_LEAVE_TYPE(x._id), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status == 204) {
      refreshList();
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert('FAILED');
    }
  }, [deleteRes.isLoading, deleteRes.isError, deleteRes.status]);

  return (
    <>
      {' '}
      {openAdd && (
        <AddLeave
          handleClose={() => {
            setOpenAdd(false);
            selectInstitute(null);
            selectLeave(null);
          }}
          refreshList={refreshList}
          institute={institute}
          selectedLeave={selectedLeave}
        />
      )}
      {Array.isArray(data) &&
        data.map((x) => (
          <div className="w-4/5">
            <div className="w-full bg-white rounded-xl h-auto mb-4">
              <div className="items-center px-6 py-3 border-b border-borderYellow">
                <div className="flex justify-between items-center">
                  <div className="font-bold w-1/3">
                    <p>{x?.institute?.name}</p>
                  </div>
                  <div className="flex gap-x-4 w-2/3 justify-end">
                    <button
                      className="w-32 border px-12 py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                      onClick={() => {
                        setOpenAdd(true);
                        selectInstitute(x.institute._id);
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 w-full gap-x-6  px-6">
                <div className="w-full py-4">
                  <p className="mb-2 font-medium ml-1">Staff Leaves</p>
                  <div className="bg-white m-auto border rounded-2xl border-borderYellow">
                    <div className="w-full flex bg-tableHeader rounded-3xl">
                      <div className="flex w-full relative text-left px-4 text-xs font-medium">
                        <div className="w-1/3 border-r-2 py-2 px-2">Leave Type</div>
                        <div className="w-1/3 border-r-2 py-2 px-2">Count</div>
                        <div className="w-1/3 py-2 px-2">Action</div>
                      </div>
                    </div>
                    <div className="showScroll" style={{ maxHeight: '55vh' }}>
                      {x?.leaves.map((y, idx) => {
                        return (
                          <div key={y?._id} className="w-full">
                            <div
                              className={`justify-center flex w-full relative text-left py-4 px-4 text-xs font-medium items-center ${
                                x?.leaves.length - 1 !== idx && 'border-b border-borderYellow'
                              }`}
                            >
                              <div className="w-1/3 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                                {y?.leave_type}
                              </div>
                              <div className="w-1/3 px-2 text-tableRowText overflow-ellipsis overflow-hidden whitespace-nowrap">
                                {y?.leave_count}
                              </div>
                              <div className={`w-1/3 px-2 gap-x-2 flex`}>
                                <button
                                  className="w-1/2 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                  onClick={() => {
                                    setOpenAdd(true);
                                    selectLeave(y);
                                  }}
                                >
                                  View
                                </button>
                                <button
                                  className="w-1/2 border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white"
                                  onClick={() => handleDelete(y)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default InstituteLeaves;
