import React, { useState, useEffect } from 'react';
import useApi from '../../api/useApi';
import { ADD_CLASSES } from '../../api/urls';
import DropDown from '../UI/DropDown';

function AddClass({ onClose, refreshList, selectedInstitution }) {
  const [_className, setClass] = useState();
  const [classgroupID, setgroupID] = useState("0");

  const [{ isLoading, isError, data, status }, addClass] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      name: _className,
      groupID : classgroupID,
    };

    addClass(ADD_CLASSES(selectedInstitution), 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && data && status) {
      onClose();
      refreshList();
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-4/12 -mt-52 rounded-2xl relative">
        <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
          Add Class
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
          alt=""
          onClick={() => onClose()}
        />
        <form className="mt-2 mb-4 mx-4 flex flex-col px-2" autoComplete="off" onSubmit={handleSubmit}>
          <div className="mt-2 mb-4 w-full relative font-semibold">
            <label htmlFor="category" className="block text-parameter text-sm">
              Class Name
            </label>
            <input
              type="text"
              placeholder="Eg- 10"
              autoComplete="off"
              value={_className}
              onChange={(e) => setClass(e.target.value)}
              className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
            />
          </div>
          <div className="w-full mb-4 ">
              <label htmlFor="category" className="block text-parameter text-sm">
                Select Group
              </label>
              <DropDown
                //  width={'w-1/3'}
                title={'Select Group'}
                data={[{"name" : "Pre Primary","ID":"1" },{"name" : "Primary","ID":"2" },{"name" : "Middle","ID":"3" },{"name" : "Secondary","ID":"4" },{"name" : "Senior - Secondary","ID":"5" } ]}
                field={'name'}
                type={'obj'}
                handleItemClick={(x) => { // console.log(" check click : ", x );
                  setgroupID(x.ID) ; // selectInstitution(x._id);
                }}
                margin={'mt-4 mx-auto'}
              />
          </div>

          <div className="flex justify-end">
            {isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <button
                type="submit"
                className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddClass;
