import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../api/useApi';
import { ADD_LEAVE_TYPE, EDIT_LEAVE_TYPE } from '../../api/urls';

function AddLeave({ handleClose, institute, refreshList, selectedLeave }) {
  const formDataModel = {
    leave_type: '',
    // staff_type: '',
    leave_count: 0,
  };

  const [formData, setFormData] = useState(formDataModel);

  const handleInputChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [{ isLoading, isError, data, status }, createInstitution] = useApi();

  useEffect(() => {
    if (selectedLeave) {
      setFormData(selectedLeave);
    }
  }, [selectedLeave]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedLeave) createInstitution(EDIT_LEAVE_TYPE(formData._id), 'PUT', formData);
    else createInstitution(ADD_LEAVE_TYPE(institute), 'POST', formData);
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 201) {
      setFormData(formDataModel);
      handleClose();
      refreshList();
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert('FAILED');
    }
  }, [isLoading, isError, data, status]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 overflow-auto py-6"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-3/5 text-sm m-auto rounded-2xl relative mt-6">
        <div className="flex justify-between py-2 border-b border-borderYellow items-center">
          <span className="font-bold px-4 py-3 text-textBlue1">{'Add Leave Type'}</span>
          <div className="flex gap-x-8 items-center relative justify-end">
            <img src="assets/img/Close.svg" className="h-3 cursor-pointer mr-4" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className=" w-full h-1/4 px-4 pb-6">
          <form className="grid grid-cols-2 gap-x-6 px-4 pb-2" onSubmit={handleSubmit}>
            {/* <div className="my-2 relative font-semibold w-full mt-4">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Staff/Student
              </label>
              <select
                type="text"
                name="staff_type"
                // placeholder="All"
                value={formData.staff_type}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              >
                <option>Select</option>
                <option>Staff</option>
                <option>Student</option>
              </select>
            </div> */}

            {/* <div></div> */}

            <div className="my-2 relative font-semibold w-full">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Leave Type
              </label>
              <input
                type="text"
                name="leave_type"
                // placeholder="All"
                value={formData.leave_type}
                onChange={handleInputChange}
                className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="my-2 w-full relative font-semibold ">
              <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                Count
              </label>
              <input
                type="text"
                name="leave_count"
                // placeholder="slug"
                value={formData.leave_count}
                onChange={handleInputChange}
                className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
              />
            </div>

            <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
              {isLoading ? (
                <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
              ) : (
                <button
                  type="submit"
                  className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                >
                  {selectedLeave ? 'Edit' : 'Create'}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddLeave;
