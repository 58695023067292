import React, { useState, useEffect } from 'react';
import useApi from '../../api/useApi';
import { HOLIDAYS } from '../../api/urls';
import { useStateValue } from '../../ContextApi/StateProvider';

function Add({ onClose }) {
  const [{ masterData }, dispatch] = useStateValue();

  const formDataModel = { type: '', name: '', date: '', state: '', district: '' };
  const sumVacationModel = { type: 'Summer Holidays', state: '', district: '', board: '', startDate: '', endDate: '' };
  const winVacationModel = { type: 'Winter Holidays', state: '', district: '', board: '', startDate: '', endDate: '' };

  const [holidayList, setHolidayList] = useState([0]);
  const [holidays, setHolidays] = useState([formDataModel]);

  const [summerList, setSummerList] = useState([0]);
  const [summerVacation, setSummerVacation] = useState([sumVacationModel]);

  const [winterList, setWinterList] = useState([0]);
  const [winterVacation, setwinterVacation] = useState([winVacationModel]);
  const [step, setStep] = useState(0);

  const addHolidayItem = (e) => {
    e.preventDefault();
    if (holidayList.length > 0) setHolidayList([...holidayList, holidayList[holidayList.length - 1] + 1]);
    else setHolidayList([0]);
  };

  const removeHolidayItem = (id) => {
    holidayList.pop();
    setHolidayList([...holidayList]);
    if (holidays.length === 1) setHolidays([]);
    else {
      holidays.splice(id, 1);
      setHolidays([...holidays]);
    }
  };

  const handleInputChange = (e, index) => {
    const x = holidays;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...holidays[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setHolidays([...x]);
  };

  const addSummerHolidayItem = (e) => {
    e.preventDefault();
    if (summerList.length > 0) setSummerList([...summerList, summerList[summerList.length - 1] + 1]);
    else setSummerList([0]);
  };

  const removeSummerHolidayItem = (id) => {
    summerList.pop();
    setSummerList([...summerList]);
    if (summerVacation.length === 1) setSummerVacation([]);
    else {
      summerVacation.splice(id, 1);
      setSummerVacation([...summerVacation]);
    }
  };

  const handleSummerVacationChange = (e, index) => {
    const x = summerVacation;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...summerVacation[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setSummerVacation([...x]);
  };

  const addWinterHolidayItem = (e) => {
    e.preventDefault();
    if (winterList.length > 0) setWinterList([...winterList, winterList[winterList.length - 1] + 1]);
    else setWinterList([0]);
  };

  const removeWinterHolidayItem = (id) => {
    winterList.pop();
    setWinterList([...winterList]);
    if (winterVacation.length === 1) setwinterVacation([]);
    else {
      winterVacation.splice(id, 1);
      setwinterVacation([...winterVacation]);
    }
  };

  const handleWinterVacationChange = (e, index) => {
    const x = winterVacation;

    let inputValue = e.target.value || '';
    if (e.target.value.startsWith('-')) {
      inputValue = e.target.value.slice(1);
    }

    const y = {
      ...winterVacation[index],
      [e.target.name]: inputValue,
    };
    x[index] = y;
    setwinterVacation([...x]);
  };

  const [{ isLoading, isError, data, status }, addHolidays] = useApi();

  const handleSubmit = (e) => {
    e.preventDefault();
    let str = '';
    holidays.forEach((x, idx) => {
      if (!x.name || !x.date || !x.type) {
        str = `Please enter all fields for entry ${idx + 1}`;
      }
    });

    if (str) {
      alert(str);
      return;
    }
    // if (selectedLeave) createInstitution(EDIT_LEAVE_TYPE(formData._id), 'PUT', formData);
    // else
    // console.log(" -- holidays check : ", holidays )
    // console.log(" -- summerVacation check : ", summerVacation )
    // console.log(" -- winterVacation check : ", winterVacation )
    addHolidays(HOLIDAYS, 'POST', { holidayList: [...holidays, ...summerVacation, ...winterVacation] });
  };

  useEffect(() => {
    if (!isLoading && !isError && status == 200) {
      onClose(true);
      alert('SUCCESS');
    }

    if (!isLoading && isError) {
      alert(data || data?.message || data?.msg || 'FAILED');
    }
  }, [isLoading, isError, data, status]);
  // console.log(summerVacation);
  return (
    <div className="w-full bg-white rounded-xl h-auto mb-4">
      <div className="flex justify-between py-2 px-6 border-b border-borderYellow items-center">
        <span className="font-bold py-3 text-textBlue1">Add Holidays</span>

        <div className="flex gap-x-8 items-center relative justify-end">
          <button
            className="border py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 bg-primary border-primary text-white w-32"
            onClick={() => {
              onClose(false);
            }}
          >
            Back
          </button>
        </div>
      </div>

      <div className=" w-full  px-4 pb-6">
        <div className="pb-3 pt-2 px-8 relative grid grid-cols-2 md:grid-cols-3 gap-x-8 border-b border-borderYellow">
          <button
            className={`py-2 w-full cursor-pointer rounded-full text-xs ${
              step === 0 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Summer Holidays
          </button>
          <button
            className={`py-2 w-full cursor-pointer rounded-full text-xs ${
              step === 1 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Winter Holidays
          </button>
          <button
            className={`py-2 w-full cursor-pointer rounded-full text-xs ${
              step === 2 ? 'bg-primary text-white' : 'bg-white text-black'
            }`}
          >
            Other Holidays
          </button>
        </div>

        <form className="px-4 pb-2" onSubmit={handleSubmit}>
          {step === 0 && (
            <div>
              <h3 className="font-medium mt-4 mb-0 pb-0 text-lg flex justify-between items-center">
                Summer Holidays
                <button
                  className="border w-32 py-2 text-xs rounded-3xl bg-white border-primary font-medium text-primary focus:outline-none flex justify-center text-center items-center"
                  onClick={addSummerHolidayItem}
                >
                  Add More
                </button>
              </h3>
              {summerList.map((x) => (
                <div className="grid grid-cols-3 gap-x-6 relative border-b border-borderYellow pb-2">
                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      State
                    </label>
                    <select
                      type="text"
                      name="state"
                      value={summerVacation[x]?.state}
                      onChange={(e) => handleSummerVacationChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option>Select</option>
                      {masterData?.state?.length &&
                        masterData?.state[0].stateList?.map((m) => <option key={m}>{m}</option>)}
                    </select>
                  </div>

                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      District
                    </label>
                    <select
                      type="text"
                      name="district"
                      value={summerVacation[x]?.district}
                      onChange={(e) => handleSummerVacationChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option>Select</option>
                      {masterData?.district
                        ?.find((c) => c.key === summerVacation[x]?.state)
                        ?.districtList?.map((m) => (
                          <option key={m}>{m}</option>
                        ))}
                    </select>
                  </div>

                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Board
                    </label>
                    <select
                      type="text"
                      name="board"
                      value={summerVacation[x]?.board}
                      onChange={(e) => handleSummerVacationChange(e, x)}
                      className="w-10/12 text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option>Select</option>
                      {masterData?.board?.map((c) => (
                        <option key={c.key}>{c.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Start Date
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={summerVacation[x]?.startDate}
                      onChange={(e) => handleSummerVacationChange(e, x)}
                      className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      End Date
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={summerVacation[x]?.endDate}
                      onChange={(e) => handleSummerVacationChange(e, x)}
                      className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>
                  <div
                    className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-10"
                    onClick={() => removeSummerHolidayItem(x)}
                  >
                    <img src="assets/img/Close.svg" alt="Close" className="h-3" />
                  </div>
                </div>
              ))}
              <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
                <button
                  className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(1);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 1 && (
            <div>
              <h3 className="font-medium mt-4 mb-0 pb-0 text-lg flex justify-between items-center">
                Winter Holidays
                <button
                  className="border w-32 py-2 text-xs rounded-3xl bg-white border-primary font-medium text-primary focus:outline-none flex justify-center text-center items-center"
                  onClick={addWinterHolidayItem}
                >
                  Add More
                </button>
              </h3>
              {winterList.map((x) => (
                <div className="grid grid-cols-3 gap-x-6 relative border-b border-borderYellow pb-2">
                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      State
                    </label>
                    <select
                      type="text"
                      name="state"
                      value={winterVacation[x]?.state}
                      onChange={(e) => handleWinterVacationChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option>Select</option>
                      {masterData?.state?.length &&
                        masterData?.state[0].stateList?.map((c) => <option key={c}>{c}</option>)}
                    </select>
                  </div>

                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      District
                    </label>
                    <select
                      type="text"
                      name="district"
                      value={winterVacation[x]?.district}
                      onChange={(e) => handleWinterVacationChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option>Select</option>
                      {masterData?.district
                        ?.find((c) => c.key === winterVacation[x]?.state)
                        ?.districtList?.map((b) => (
                          <option key={b}>{b}</option>
                        ))}
                    </select>
                  </div>

                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Board
                    </label>
                    <select
                      type="text"
                      name="board"
                      value={winterVacation[x]?.board}
                      onChange={(e) => handleWinterVacationChange(e, x)}
                      className="w-10/12 text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option>Select</option>
                      {masterData?.board?.map((c) => (
                        <option key={c.key}>{c.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Start Date
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={winterVacation[x]?.startDate}
                      onChange={(e) => handleWinterVacationChange(e, x)}
                      className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      End Date
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      value={winterVacation[x]?.endDate}
                      onChange={(e) => handleWinterVacationChange(e, x)}
                      className="w-full rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div
                    className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-10"
                    onClick={() => removeWinterHolidayItem(x)}
                  >
                    <img src="assets/img/Close.svg" alt="Close" className="h-3" />
                  </div>
                </div>
              ))}

              <div className="w-full mt-4 col-span-2 gap-x-4 flex justify-between items-center">
                <button
                  className="border px-12 py-2 text-xs rounded-3xl bg-white border-borderLight font-medium text-textLight focus:outline-none flex justify-center text-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(0);
                  }}
                >
                  Previous
                </button>
                <button
                  className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(2);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <h3 className="font-medium mt-4 mb-0 pb-0 text-lg flex justify-between items-center">
                Other Holidays{' '}
                <button
                  className="border w-32 py-2 text-xs rounded-3xl bg-white border-primary font-medium text-primary focus:outline-none flex justify-center text-center items-center"
                  onClick={addHolidayItem}
                >
                  Add More
                </button>
              </h3>
              {holidayList.map((x) => (
                <div className="grid grid-cols-3 gap-x-6 relative border-b border-borderYellow pb-2">
                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Holiday Type
                    </label>
                    <select
                      type="text"
                      name="type"
                      value={holidays[x]?.type}
                      onChange={(e) => handleInputChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    >
                      <option>Select</option>
                      <option>Gazetted Holiday</option>
                      <option>Regional Holiday</option>
                      <option>Restricted Holiday</option>
                      <option>State Holiday</option>
                    </select>
                  </div>

                  <div className="my-2 relative font-semibold w-full">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={holidays[x]?.name}
                      onChange={(e) => handleInputChange(e, x)}
                      className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  <div className="my-2 w-full relative font-semibold">
                    <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                      Date
                    </label>
                    <input
                      type="date"
                      name="date"
                      value={holidays[x]?.date}
                      onChange={(e) => handleInputChange(e, x)}
                      className="w-10/12 rounded-2xl text-xs border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                    />
                  </div>

                  {(holidays[x]?.type === 'Regional Holiday' || holidays[x]?.type === 'State Holiday') && (
                    <div className="my-2 relative font-semibold w-full">
                      <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                        State
                      </label>
                      <select
                        type="text"
                        name="state"
                        value={holidays[x]?.state}
                        onChange={(e) => handleInputChange(e, x)}
                        className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                      >
                        <option>Select</option>
                        {masterData?.state?.length &&
                          masterData?.state[0].stateList?.map((x) => <option key={x}>{x}</option>)}
                      </select>
                    </div>
                  )}

                  {holidays[x]?.type === 'Regional Holiday' && (
                    <div className="my-2 relative font-semibold w-full">
                      <label htmlFor="budget" className="block text-parameter text-sm text-textDark">
                        District
                      </label>
                      <select
                        type="text"
                        name="district"
                        value={holidays[x]?.district}
                        onChange={(e) => handleInputChange(e, x)}
                        className="w-full text-xs rounded-3xl border border-borderLight text-textDark px-4 mt-2 py-2 focus:outline-none"
                      >
                        <option>Select</option>
                        {masterData?.district
                          ?.find((m) => m.key === holidays[x]?.state)
                          ?.districtList?.map((c) => (
                            <option key={c}>{c}</option>
                          ))}
                      </select>
                    </div>
                  )}

                  <div
                    className="rounded-full bg-borderLight absolute p-2 right-1 cursor-pointer top-10"
                    onClick={() => removeHolidayItem(x)}
                  >
                    <img src="assets/img/Close.svg" alt="Close" className="h-3" />
                  </div>
                </div>
              ))}

              <div className="w-full mt-4 flex justify-end col-span-2 gap-x-4">
                <button
                  className="border px-12 py-2 text-xs rounded-3xl bg-white border-borderLight font-medium text-textLight focus:outline-none flex justify-center text-center items-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(1);
                  }}
                >
                  Previous
                </button>
                {isLoading ? (
                  <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
                ) : (
                  <>
                    <button
                      type="submit"
                      className="border px-12 py-2 text-xs rounded-3xl bg-primary border-primary font-medium text-white focus:outline-none flex justify-center text-center items-center"
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default Add;
