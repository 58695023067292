import React, { useState, useEffect } from 'react';
import useApi from '../../api/useApi';
import { ADD_SECTION, GET_SECTIONS, DELETE_SECTION } from '../../api/urls';

function AddSection({ selectedInstitution, onClose, selectedClass }) {
  const [sections, setSections] = useState([]);
  const [newSection, setNewSection] = useState();

  const [{ isLoading, isError, status }, addSection] = useApi();
  const [sectionsRes, getSections] = useApi();
  const [deleteSectionRes, deleteSection] = useApi();

  useEffect(() => {
    if (selectedInstitution && selectedClass) getSections(GET_SECTIONS(selectedInstitution, selectedClass._id), 'GET');
  }, [selectedClass, selectedInstitution]);

  useEffect(() => {
    if (!sectionsRes.isLoading && !sectionsRes.isError && sectionsRes.data) {
      setSections(sectionsRes.data);
    }
  }, [sectionsRes]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      name: newSection,
      class_id: selectedClass._id,
    };

    addSection(ADD_SECTION(selectedInstitution), 'POST', body);
  };

  useEffect(() => {
    if (!isLoading && !isError && status) {
      onClose();
    }
  }, [isLoading, isError, status]);

  const handleDeleteSection = (_section) => {
    deleteSection(DELETE_SECTION(selectedClass._id, _section._id), 'DELETE', { instituteId: selectedInstitution });
  };

  useEffect(() => {
    if (!deleteSectionRes.isLoading && !deleteSectionRes.isError && deleteSectionRes.status == 201) {
      alert('Section Deleted successfully');
      onClose();
    }

    if (!deleteSectionRes.isLoading && deleteSectionRes.isError) {
      alert('Something went wrong');
    }
  }, [deleteSectionRes]);

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-5/12 text-sm block m-auto mt-20 rounded-2xl relative">
        <div className="border-b border-borderYellow px-6 py-4 flex justify-between items-center">
          <span className="block font-bold ">{selectedClass?.name} - Add Section</span>
          <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={onClose} />
        </div>
        <div className="my-4">
          <div className="bg-white m-auto border rounded-2xl border-borderYellow mx-6 my-4">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative text-left px-4 text-xs font-medium">
                <div className="w-1/3 border-r-2 py-2 px-2">Class</div>
                <div className="w-1/3 border-r-2 py-2 px-2">Section</div>
                <div className="w-1/3 py-2 px-2">Action</div>
              </div>
            </div>
            <div className="showScroll overflow-auto" style={{ maxHeight: '25vh' }}>
              {sections.map((x, idx) => {
                return (
                  <div className="w-full">
                    <div
                      className={`justify-center flex w-full relative text-left py-2 px-4 text-xs font-medium items-center ${
                        idx !== sections.length - 1 && 'border-b border-borderYellow'
                      }`}
                    >
                      <div className="w-1/3 px-2 py-2 text-textDark ">{selectedClass.name}</div>
                      <div className="w-1/3 px-2 py-2 text-textDark">{x.name}</div>
                      <div className="w-1/3 px-2 py-2 text-textDark">
                        <button
                          className=" border  py-2 rounded-3xl text-xs focus:outline-none flex justify-center items-center px-6 mr-3 border-primary text-primary"
                          onClick={() => {
                            handleDeleteSection(x);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="w-full border-t border-borderYellow px-6 py-4">
            <span className="block font-bold ">Create Section</span>
            <div className="w-full grid grid-cols-2 gap-x-4">
              <div className="mt-2 mb-2 w-full relative font-semibold ">
                <label htmlFor="budget" className="block text-textDark text-xs">
                  Section
                </label>
                <input
                  type="text"
                  placeholder="Enter Section"
                  onChange={(e) => {
                    setNewSection(e.target.value);
                  }}
                  className="w-full  rounded-3xl border border-borderLight px-4 mt-2 py-2 text-xs focus:outline-none"
                />
              </div>
            </div>
          </div>

          <button
            className="w-40 mr-6 border ml-auto py-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center h-7 border-primary text-white bg-primary"
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddSection;
