import React, { useState, useEffect } from 'react';
import Students from '../components/StudentManagement/Students';
import AddStudent from '../components/StudentManagement/AddStudent';

import useApi from '../api/useApi';
import { GET_ALL_STUDENTS, GET_INSTITUTES, DELETE_USER } from '../api/urls';
import UploadCSV from '../components/StudentManagement/UploadCSV';

function StudentManagement() {
  const [open, setOpen] = useState(false);
  const [institutes, setInstitutes] = useState([]);
  const [selectedInstitution, selectInstitution] = useState();
  const [students, setStudents] = useState();
  const [selectedStudent, selectStudent] = useState();
  const [openUploadCsv, setOpenUploadCsv] = useState(false);

  const [instituteRes, getInstitutes] = useApi();
  const [studentsRes, getStudents] = useApi();
  const [deleteRes, deleteUser] = useApi();

  useEffect(() => {
    getInstitutes(GET_INSTITUTES, 'GET');
  }, []);

  useEffect(() => {
    if (!instituteRes.isLoading && !instituteRes.isError && instituteRes.data) {
      setInstitutes(instituteRes.data.response);
    }
  }, [instituteRes]);

  useEffect(() => {
    if (selectedInstitution) {
      getStudents(GET_ALL_STUDENTS(selectedInstitution), 'GET');
    }
  }, [selectedInstitution]);

  useEffect(() => {
    if (!studentsRes.isLoading && !studentsRes.isError && studentsRes.data) {
      setStudents(studentsRes.data);
    }
  }, [studentsRes]);

  const toggleView = () => {
    setOpen((prev) => !prev);
  };

  const openDetailsView = (x, y) => {
    selectStudent(x);
    toggleView();
  };

  const refreshList = () => {
    getStudents(GET_ALL_STUDENTS(selectedInstitution), 'GET');
  };

  const handleDelete = (x) => {
    deleteUser(DELETE_USER(x._id, 'st'), 'DELETE');
  };

  useEffect(() => {
    if (!deleteRes.isLoading && !deleteRes.isError && deleteRes.status) {
      refreshList();
      alert('SUCCESS');
    }

    if (!deleteRes.isLoading && deleteRes.isError) {
      alert('FAILED');
    }
  }, [deleteRes]);

  return (
    <div className="w-4/5 pb-10">
      {open && (
        <AddStudent
          toggleView={toggleView}
          selectedStudent={selectedStudent}
          refreshList={refreshList}
          selectedInstitution={selectedInstitution}
        />
      )}

      {openUploadCsv && (
        <UploadCSV institutes={institutes} handleClose={() => setOpenUploadCsv(false)} refreshList={refreshList} />
      )}

      <Students
        openDetails={openDetailsView}
        data={students}
        institutes={institutes}
        selectInstitution={selectInstitution}
        setOpenUploadCsv={setOpenUploadCsv}
        isLoading={studentsRes.isLoading}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default StudentManagement;
