import React, { useState, useEffect, useRef } from 'react';
import DropDown from '../UI/DropDown';
import ExcelExportBtn from '../UI/ExcelExportBtn';
import StudentCsvTemplate from '../../CSVTemplates/NewStudent';
import { GET_CLASSES, GET_SECTIONS, CREATE_BULK_USERS } from '../../api/urls';
import useApi from '../../api/useApi';
import { read, utils } from 'xlsx';
import moment from 'moment';

function UploadCSV({ handleClose, institutes, refreshList }) {
  const [selectedInstitution, selectInstitution] = useState();
  const [csv, setCSV] = useState();
  const [csvArray, setCsvArray] = useState();
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [selectedClass, selectClass] = useState();
  const [selectedSection, selectSection] = useState();

  const csvRef = useRef();

  const [classesRes, getClasses] = useApi();
  const [sectionsRes, getSections] = useApi();
  const [uploadRes, uploadData] = useApi();

  useEffect(() => {
    if (selectedInstitution) getClasses(GET_CLASSES(selectedInstitution), 'GET');
  }, [selectedInstitution]);

  useEffect(() => {
    if (selectedInstitution && selectedClass) getSections(GET_SECTIONS(selectedInstitution, selectedClass._id), 'GET');
  }, [selectedInstitution, selectedClass]);

  useEffect(() => {
    if (!classesRes.isLoading && !classesRes.isError && classesRes.data) {
      setClasses(classesRes.data);
    }
  }, [classesRes]);

  useEffect(() => {
    if (!sectionsRes.isLoading && !sectionsRes.isError && sectionsRes.data) {
      setSections(sectionsRes.data);
    }
  }, [sectionsRes]);

  function ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedInstitution) {
      alert('Select Institute');
      return;
    }

    if (!selectedClass) {
      alert('Select Class');
      return;
    }

    if (!selectedSection) {
      alert('Select Section');
      return;
    }

    let isDataMissing = false;

    let body = [];
    if (csvArray.length > 0) {
      csvArray.every((x) => {
        // console.log(new Date(ExcelDateToJSDate(x?.DateOfBirth)));
        if (!x.Email) {
          alert("Some student's email is not added. Please check and upload");
          isDataMissing = true;
          return false;
        }

        if (!x.Father_Name && !x.Mother_Name && !x.Guardian_Name) {
          alert('Parent details of some student is not added. Please check and upload');
          isDataMissing = true;
          return false;
        }

        if (!x.PERMANENT_Address_State) {
          alert("Some Staff member's state is not added. Please check and upload");
          isDataMissing = true;
          return false;
        }

        let stu = {
          instituteId: selectedInstitution,
          full_name: x.Name || '',
          email_id: x.Email || '',
          gender: x.Gender || '',
          placeOfBirth: x.PlaceOfBirth || '',
          dateOfBirth: x?.DateOfBirth ? new Date(ExcelDateToJSDate(x?.DateOfBirth)) : '',
          // aadhar: x.Aadhar || '',
          bloodgrp: x.BloodGroup || '',
          roleType: 'st',
          role: 'student',
          rollNumber: x.RollNumber || '',
          address: {
            hno: x.PERMANENT_Address_Hno || '',
            society: x.PERMANENT_Address_Society || '',
            district: x.PERMANENT_Address_District || '',
            city: x.PERMANENT_Address_City || '',
            state: x.PERMANENT_Address_State
              ? x.PERMANENT_Address_State.toLowerCase() === 'j&k'
                ? 'Jammu and Kashmir'
                : x.PERMANENT_Address_State
              : '',
            pincode: x.PERMANENT_Address_Pincode || '',
          },
          caddress: {
            hno: x.Correspondance_Address_Hno || '',
            society: x.Correspondance_Address_Society || '',
            district: x.Correspondance_Address_District || '',
            city: x.Correspondance_Address_City || '',
            state: x.Correspondance_Address_State || '',
            pincode: x.Correspondance_Address_Pincode || '',
          },
          lastSchool: {
            schoolName: x.LastSchoolName || '',
            class: x.LastClass || '',
            address: x.LastSchoolAddress || '',
            medium: x.LastSchoolAMedium || '',
          },
          parentDetail: [],
        };
        // console.log(stu);
        if (x.Father_Name) {
          stu.parentDetail.push({
            father_name: x.Father_Name || '',
            companyName: x.Father_companyName || '',
            designation: x.Father_designation || '',
            officeAddress: x.Father_officeAddress || '',
            phone: x.Father_phone || '',
            email_Id: x.Father_email_Id || '',
            role: 'parent',
          });
        }

        if (x.Mother_Name) {
          stu.parentDetail.push({
            mother_name: x.Mother_Name || '',
            companyName: x.Mother_companyName || '',
            designation: x.Mother_designation || '',
            officeAddress: x.Mother_officeAddress || '',
            phone: x.Mother_phone || '',
            email_Id: x.Mother_email_Id || '',
            role: 'parent',
          });
        }

        if (x.Guardian_Name) {
          stu.parentDetail.push({
            guardian_name: x.Guardian_Name || '',
            companyName: x.Guardian_companyName || '',
            designation: x.Guardian_designation || '',
            officeAddress: x.Guardian_officeAddress || '',
            phone: x.Guardian_phone || '',
            email_Id: x.Guardian_email_Id || '',
            role: 'parent',
          });
        }

        body.push(stu);
        return true;
      });
    }

    if (isDataMissing) {
      return;
    }

    const studentData = {
      user: body,
    };

    uploadData(CREATE_BULK_USERS(selectedInstitution, selectedClass._id, selectedSection._id), 'POST', studentData);
  };

  useEffect(() => {
    if (!uploadRes.isLoading && !uploadRes.isError && uploadRes.status == 200) {
      alert('SUCCESS');
      handleClose();
      refreshList();
    }

    if (!uploadRes.isLoading && uploadRes.isError) {
      alert('Failed to upload');
    }
  }, [uploadRes]);

  useEffect(() => {
    if (csv) {
      readData();
    }
  }, [csv]);

  const readData = () => {
    const file = csv;
    // const reader = new FileReader();

    // reader.onload = function (e) {
    //   const text = e.target.result;
    //   processCSV(text);
    // };

    // reader.readAsText(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        setCsvArray(rows);
        // console.log(rows);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // const processCSV = (str, delim = ',') => {
  //   const headers = str
  //     .slice(0, str.indexOf('\n'))
  //     .split(delim)
  //     .map((x) => x.replace('\r', ''));

  //   let rows = str.slice(str.indexOf('\n') + 1).split('\n');
  //   rows = rows.filter((x) => x.length > 3);

  //   const newArray = rows.map((row) => {
  //     const values = row.split(delim).map((x) => x.replace('\r', '').replace(/['"]+/g, ''));

  //     const eachObject = headers.reduce((obj, header, i) => {
  //       obj[header] = values[i];
  //       return obj;
  //     }, {});
  //     return eachObject;
  //   });
  //   console.log(newArray);
  //   setCsvArray(newArray);
  // };

  return (
    <div
      className="pb-10 w-full fixed overflow-auto left-0 bottom-0 top-0 z-30"
      style={{
        background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))',
      }}
    >
      <div className="relative w-2/5 pb-6 mx-auto h-auto mt-6 bg-white rounded-2xl">
        <div className="flex justify-between items-center py-3  border-b border-borderYellow">
          <div className="font-bold px-8">
            <p>Upload Student CSV</p>
          </div>
          <div className="flex px-8 gap-x-8 items-center">
            <img src="assets/img/Close.svg" className=" h-3 cursor-pointer" alt="" onClick={handleClose} />
          </div>
        </div>

        <div className="px-8 pt-6 float-right">
          <ExcelExportBtn
            data={[]}
            fileName={'student_CSV.csv'}
            text={'Download CSV Template'}
            width={'w-1/3'}
            headers={StudentCsvTemplate}
          />
        </div>

        <form autoComplete="off" className="px-8" onSubmit={handleSubmit}>
          <div className="flex w-full">
            <div className="pb-4 w-full ">
              <div className="my-6 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Select Institution<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  width={'w-full'}
                  title={'Select'}
                  data={institutes}
                  field={'name'}
                  type={'obj'}
                  handleItemClick={(x) => {
                    selectInstitution(x._id);
                  }}
                  margin={'mt-2 mx-auto'}
                />
              </div>

              <div className="my-6 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Select Class<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  width={'w-full'}
                  title={'Select'}
                  data={classes}
                  field={'name'}
                  type={'obj'}
                  handleItemClick={(x) => {
                    selectClass(x);
                  }}
                  margin={'mt-2 mx-auto'}
                />
              </div>

              <div className="my-6 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Select Section<span className="text-red-500 ml-1">*</span>
                </label>
                <DropDown
                  width={'w-full'}
                  title={'Select'}
                  data={sections}
                  field={'name'}
                  type={'obj'}
                  handleItemClick={(x) => {
                    selectSection(x);
                  }}
                  margin={'mt-2 mx-auto'}
                />
              </div>

              <div className="my-6 w-full relative font-semibold ">
                <label htmlFor="budget" className="text-textDark font-medium text-sm">
                  Student CSV
                </label>
                <div className="relative flex">
                  <input
                    hidden
                    ref={csvRef}
                    type="file"
                    onChange={(e) => setCSV(e.target.files[0])}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <input
                    type="text"
                    disabled
                    value={csv?.name}
                    className="w-full h-9 rounded-3xl text-xs border border-borderLight px-4 mt-2 py-2 focus:outline-none"
                  />
                  <button
                    className="px-10 py-2 rounded-3xl bg-primary text-xs text-white focus:outline-none flex justify-center text-center items-center h-9 mt-2 absolute right-0"
                    onClick={(e) => {
                      e.preventDefault();
                      csvRef.current.click();
                    }}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-4 justify-end">
            {uploadRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 m-auto mt-16" alt="" />
            ) : (
              <button
                className="w-40 border px-8 py-2 rounded-3xl text-xs text-white bg-primary border-primary focus:outline-none flex justify-center items-center"
                type="submit"
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default UploadCSV;
