const STUDENT_CSV_TEMPLATE = [
  'Name',
  'RollNumber',
  'Email',
  'Gender',
  'PlaceOfBirth',
  'DateOfBirth',
  // 'Aadhar',
  'BloodGroup',
  'PERMANENT_Address_Hno',
  'PERMANENT_Address_Society',
  'PERMANENT_Address_District',
  'PERMANENT_Address_City',
  'PERMANENT_Address_State',
  'PERMANENT_Address_Pincode',
  'Correspondance_Address_Hno',
  'Correspondance_Address_Society',
  'Correspondance_Address_District',
  'Correspondance_Address_City',
  'Correspondance_Address_State',
  'Correspondance_Address_Pincode',
  'Father_Name',
  'Father_companyName',
  'Father_designation',
  'Father_officeAddress',
  'Father_phone',
  'Father_email_Id',
  'Mother_Name',
  'Mother_companyName',
  'Mother_designation',
  'Mother_officeAddress',
  'Mother_phone',
  'Mother_email_Id',
  'Guardian_Name',
  'Guardian_companyName',
  'Guardian_designation',
  'Guardian_officeAddress',
  'Guardian_phone',
  'Guardian_email_Id',
  'LastSchoolName',
  'LastClass',
  'LastSchoolAddress',
  'LastSchoolAMedium',
  // 'Admission_Fee_Amount',
  // 'paidVia',
  // 'challanNo',
];

export default STUDENT_CSV_TEMPLATE;
