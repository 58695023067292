import React from 'react';

export default function NonTeachingStaff({
  setCreateStaff,
  setSearch,
  selectStaff,
  setSearchName,
  setStaffRole,
  data,
  setOpenUploadCsv,
  title,
  handleDelete,
}) {
  return (
    <div className="w-full bg-white rounded-xl h-auto mt-4">
      <div className="items-center px-6 py-3 border-b border-borderYellow">
        <div className="flex justify-between items-center">
          <div className="font-bold ">
            <p>{title}</p>
          </div>
          <div className="flex">
            {/* <button
              className="w-36 border py-2 rounded-3xl bg-primary text-xs  text-white focus:outline-none flex justify-center text-center items-center"
              onClick={() => {
                setCreateStaff(true);
                setStaffRole(title === 'Teaching' ? 'teaching' : 'non-teaching');
              }}
            >
              Add Staff
            </button> */}

            <button
              className=" border w-36 py-2 mx-4 rounded-3xl text-xs focus:outline-none flex justify-center items-center bg-primary border-primary text-white"
              onClick={() => {
                setOpenUploadCsv(true);
                setStaffRole(title === 'Teaching Staff' ? 'teaching' : 'non-teaching');
              }}
            >
              Upload CSV
            </button>

            <div className="w-44 border border-tableRowText px-3 py-2 rounded-3xl text-xs  focus:outline-none flex">
              <img src="assets/img/Search.svg" className=" w-4 mr-2 " alt="" />
              <input
                type="text"
                placeholder="Search staff"
                autoComplete="off"
                // value="Search staff"
                className="text-left w-full font-medium bg-transparent text-tableRowText  focus:outline-none"
                onChange={(x) => {
                  setSearchName(x.target.value);
                }}
                onKeyDown={(x) => {
                  if (x.which === 13) {
                    setSearch((prev) => !prev);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-full">
        <div className="w-full py-4 px-6">
          <div className="bg-white m-auto border border-borderYellow rounded-2xl">
            <div className="w-full flex bg-tableHeader rounded-3xl">
              <div className="flex w-full relative px-2 text-xs font-medium">
                <div className="w-2/12 border-r-2 p-2">Photograph</div>
                <div className="w-3/12 border-r-2 p-2">ROLEDin ID</div>
                <div className="w-3/12 border-r-2 p-2">Name</div>
                <div className="w-3/12 border-r-2 p-2">Designation</div>
                {/* <div className="w-3/12 border-r-2 p-2">Last Date</div> */}
                <div className="w-1/4 p-2">Action</div>
              </div>
            </div>
            <div
              className="showScroll"
              style={{
                maxHeight: '45vh',
              }}
            >
              {data.map((x, idx) => (
                <div className="w-full">
                  <div
                    className={`justify-center items-center flex w-full relative px-2 py-2 text-xs font-medium ${
                      data.length - 1 !== idx && 'border-b border-borderYellow'
                    }`}
                  >
                    <div className="w-2/12 px-4">
                      <img
                        className="schoolLogo"
                        style={{
                          width: '3em',
                          height: '3em',
                        }}
                        src={
                          x.fileArray.length > 0 && x.fileArray[0].profileImg.location
                            ? x.fileArray[0].profileImg.location
                            : '/assets/img/user_placeholder.svg'
                        }
                      />
                    </div>
                    <div className="w-3/12 text-tableRowText px-2">{x.username}</div>
                    <div className="w-3/12  px-2">{x.full_name}</div>
                    <div className="w-3/12 text-tableRowText px-2">
                      {x.jobProfile && x.jobProfile.map((x) => x.title).join(' ,')}
                    </div>
                    <div className="w-1/4 flex justify-center items-center px-2">
                      <button
                        className="mr-3  border px-2 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                        onClick={() => {
                          selectStaff(x);
                        }}
                      >
                        View & Edit
                      </button>

                      <button
                        className="  border px-4 py-2 rounded-3xl text-xs text-primary border-primary focus:outline-none"
                        onClick={() => {
                          handleDelete(x, title === 'Teaching Staff' ? 'te' : 'nt');
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
