import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { LOG_OUT } from '../../api/urls';
import useApi from '../../api/useApi';
import { useStateValue } from '../../ContextApi/StateProvider';

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();

  const [{ user }, dispatch] = useStateValue();

  const [response, logOut] = useApi();

  const handleLogout = () => {
    logOut(LOG_OUT, 'DELETE');
  };

  useEffect(() => {
    if (!response.isLoading && !response.isError && [201, 202, 200, 204].includes(response.status)) {
      Cookies.remove('auth');

      dispatch({
        type: 'TOKEN',
        token: null,
      });
      dispatch({
        type: 'USER',
        user: null,
      });
      history.push('/');
    }

    if (response.isError) {
    }
  }, [response]);

  return (
    <div
      className={`w-18 bg-white h-full rounded-r-xl text-center customScrollBar flex-col flex`}
      style={{ overflowY: 'scroll', height: '96vh' }}
    >
      <img src="assets/img/roledin2.svg" alt="" className="w-10 h-10 mx-auto mt-4" />
      <div className="my-4 py-3 border-t border-b border-tableHeader">
        <p className="font-semibold">Admin Panel</p>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('institution') || location.pathname == '/'
            ? 'bg-primary text-white rounded-3xl'
            : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/institution`)}>
          Institution
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('student') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/student`)}>
          Student Management
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('staff') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/staff`)}>
          Staff Management
        </div>
      </div>

      <div
        className={`mx-5 mb-2 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('social') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/social`)}>
          Social
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('pendingFee') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/pendingFee`)}>
          Pending Fee
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('incident') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/incident`)}>
          Incident Report
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('class-section') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/class-section`)}>
          Class & Section
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('cron-job') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/cron-job`)}>
          Cron Job
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('ai-model') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/ai-model`)}>
          AI Models
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('fee-structure') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/fee-structure`)}>
          Fee Structure
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('owner') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/owner`)}>
          Owner
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('principal') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/principal`)}>
          Principal
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('institute-leaves') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/institute-leaves`)}>
          Institute Leaves
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('institute-holiday') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/institute-holiday`)}>
          Institute Holidays
        </div>
      </div>

      <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('users') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/users`)}>
          Users
        </div>
      </div>

      {/* <div
        className={`mx-5 mb-1 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('student-mapping') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/student-mapping`)}>
          Student Mapping
        </div>
      </div> */}
      <div
        className={`mx-5 mb-2 relative flex font-semibold cursor-pointer ${
          location.pathname.includes('feedbacks') ? 'bg-primary text-white rounded-3xl' : 'text-gray-500'
        }`}
      >
        <div className="py-2 px-4 text-sm text-left" onClick={(e) => history.push(`/feedbacks`)}>
          RoledIn Feedbacks
        </div>
      </div>

      <div className=" mx-auto rounded-lg mt-auto py-4 mb-2 flex flex-col">
        <div>
          {/* <h3 className="text-primary text-sm">{user?.full_name}</h3> */}
          {/* <h4 className="text-textDark text-xs">{user?.email_id}</h4> */}
        </div>
        <button
          className="py-2 px-4 text-xs text-center border border-primary text-primary rounded-lg mx-auto"
          onClick={handleLogout}
        >
          Log Out
        </button>
      </div>
    </div>
  );
};
export default Sidebar;
